/* eslint-disable prettier/prettier */
import { createReducer } from 'typesafe-actions';

import {
  ListCategoryResponse,
  ListListingResponse,
} from 'apis/marketplace.api';

import {
  getListCategory,
  getListListing,
  getListListingBusiness,
} from './marketplace.action';

export interface MarketplaceStoreModel {
  loading: boolean;
  listCategory: ListCategoryResponse;
  listListing: ListListingResponse;
  listListingBusiness: ListListingResponse;
}
const INITIAL_STATE: MarketplaceStoreModel = {
  loading: false,
  listCategory: null,
  listListing: null,
  listListingBusiness: null,
};
const marketplaceReducer = createReducer<MarketplaceStoreModel>(INITIAL_STATE)
  .handleAction(getListCategory.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListCategory.success, (state, { payload }) => ({
    ...state,
    listCategory: payload,
    loading: false,
  }))
  .handleAction(getListCategory.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getListListing.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListListing.success, (state, { payload }) => ({
    ...state,
    listListing: payload,
    loading: false,
  }))
  .handleAction(getListListing.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getListListingBusiness.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListListingBusiness.success, (state, { payload }) => ({
    ...state,
    listListingBusiness: payload,
    loading: false,
  }))
  .handleAction(getListListingBusiness.failure, (state) => ({
    ...state,
    loading: false,
  }));
export default marketplaceReducer;
