import { put, takeLatest, call } from '@redux-saga/core/effects';

import {
  getDetailTreePermissionApi,
  getMineRoleApi,
  getRolePermissionApi,
} from 'apis/permission.api';
import { toastError } from 'helpers/notification.helper';
import { compact } from 'lodash';
import { UrlAndAction } from 'models/store/permission.model';
import {
  getListTreePermission,
  getListRolePermission,
  getRoleUrlAndAction,
} from './permission.action';

function* handleGetListRolePermission({
  payload,
}: ReturnType<typeof getListRolePermission.request>) {
  try {
    const response: Await<ReturnType<typeof getRolePermissionApi>> = yield call(
      getRolePermissionApi as any,
      payload,
    );
    const data = response;
    yield put(getListRolePermission.success(data));
  } catch (e) {
    toastError(e);
    yield put(getListRolePermission.failure(e));
  }
}

function* handleGetListTreePermission({
  payload,
}: ReturnType<typeof getListTreePermission.request>) {
  try {
    const response: Await<ReturnType<
      typeof getDetailTreePermissionApi
    >> = yield call(getDetailTreePermissionApi as any, payload);
    const data = response;
    yield put(getListTreePermission.success(data));
  } catch (e) {
    toastError(e);
    yield put(getListTreePermission.failure(e));
  }
}

function* handleGetRoleUrlAndAction() {
  try {
    const response: Await<ReturnType<typeof getMineRoleApi>> = yield call(
      getMineRoleApi,
    );
    const param = {
      isRoot: response.isRoot,
      list: !(response.permissions as Array<UrlAndAction>)?.length
        ? ([] as Array<UrlAndAction>)
        : [
            ...(response.permissions as Array<UrlAndAction>),
            // ...compact(RouterNotAcceptPermission as Array<UrlAndAction>),
          ],
    };
    yield put(getRoleUrlAndAction.success(param));
  } catch (e) {
    yield put(getRoleUrlAndAction.failure());
    toastError(e);
  }
}

export default function* permissionSaga() {
  yield takeLatest(getListTreePermission.request, handleGetListTreePermission);
  yield takeLatest(getListRolePermission.request, handleGetListRolePermission);
  yield takeLatest(getRoleUrlAndAction.request, handleGetRoleUrlAndAction);
}
