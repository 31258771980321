/* eslint-disable prettier/prettier */
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getListCategoryApi,
  getListListingApi,
  getListListingBusinessPendingApi,
} from 'apis/marketplace.api';

import {
  getListCategory,
  getListListing,
  getListListingBusiness,
} from './marketplace.action';

function* handleGetListCategory({
  payload,
}: ReturnType<typeof getListCategory.request>) {
  try {
    const response: Await<ReturnType<typeof getListCategoryApi>> = yield call(
      getListCategoryApi,
      payload,
    );
    const { data } = response;
    yield put(getListCategory.success(data));
  } catch (e) {
    yield put(getListCategory.failure(e));
  }
}

function* handleGetListListing({
  payload,
}: ReturnType<typeof getListListing.request>) {
  try {
    const response: Await<ReturnType<typeof getListListingApi>> = yield call(
      getListListingApi,
      payload,
    );
    const { data } = response;
    yield put(getListListing.success(data));
  } catch (e) {
    yield put(getListListing.failure(e));
  }
}

function* handleGetListBusinessPendingListing({
  payload,
}: ReturnType<typeof getListListingBusiness.request>) {
  try {
    const response: Await<ReturnType<
      typeof getListListingBusinessPendingApi
    >> = yield call(getListListingBusinessPendingApi, payload);
    const { data } = response;
    yield put(getListListingBusiness.success(data));
  } catch (e) {
    yield put(getListListingBusiness.failure(e));
  }
}

export default function* marketplaceSaga() {
  yield takeLatest(
    getListListingBusiness.request,
    handleGetListBusinessPendingListing,
  );
  yield takeLatest(getListCategory.request, handleGetListCategory);
  yield takeLatest(getListListing.request, handleGetListListing);
}
