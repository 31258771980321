import React, { FC, Suspense, lazy } from 'react';
import { RouteProps, Switch, Route, Redirect } from 'react-router-dom';

import ROUTES, { PREFIX_ROUTES } from 'constants/routes.constant';
import AuthLayout from 'layouts/auth/auth.layout';

const Login = lazy(() => import('containers/login/Login'));
const Verify2FA = lazy(() => import('containers/login/verify-2fa/Verify2FA'));
const routes: Array<RouteProps> = [
  { path: ROUTES.AUTH.LOGIN, component: Login },
  { path: ROUTES.AUTH.VERIFY_2FA, component: Verify2FA },
];

const AuthRoute: FC = () => {
  return (
    <AuthLayout>
      <Suspense fallback={<div />}>
        <Switch>
          {routes.map((i) => (
            <Route
              key={String(i.path)}
              exact={i.exact}
              path={i.path}
              component={i.component}
            />
          ))}
          <Route
            path={PREFIX_ROUTES.AUTH}
            component={() => <Redirect to={ROUTES.AUTH.LOGIN} />}
          />
        </Switch>
      </Suspense>
    </AuthLayout>
  );
};

export default AuthRoute;
