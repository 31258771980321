import { GetStatisticResponsive } from 'models/store/companies.model';
import {
  ListVerificationsResponse,
  VerificationsDetail,
  CommonApiParam,
  CompanyDetail,
  ViewCompanyResponse,
  DeactiveCompanyBody,
  ActiveCompanyBody,
} from 'models/store/verifications-by-partner.model';
import { createAsyncAction } from 'typesafe-actions';

interface objectMessage {
  field: string;
  message: Array<string>;
}

interface LoginFailResponse {
  message: Array<objectMessage>;
}
export const getListVerifications = createAsyncAction(
  `@verificationsByPartner/GET_LIST_VERIFICATIONS_BY_PARTNER_REQUEST`,
  `@verificationsByPartner/GET_LIST_VERIFICATIONS_BY_PARTNER_SUCCESS`,
  `@verificationsByPartner/GET_LIST_VERIFICATIONS_BY_PARTNER_FAIL`,
)<CommonApiParam, ListVerificationsResponse, LoginFailResponse>();

export const getStatisticCharts = createAsyncAction(
  `@companies/GET_STATISTIC_REQUEST`,
  `@companies/GET_STATISTIC_SUCCESS`,
  `@companies/GET_STATISTIC_FAIL`,
)<CommonApiParam, GetStatisticResponsive, void>();
export const handleDeactiveCompanyAction = createAsyncAction(
  `@companies/DEACTIVE_COMPANY_REQUEST`,
  `@companies/DEACTIVE_COMPANY_SUCCESS`,
  `@companies/DEACTIVE_COMPANY_FAIL`,
)<DeactiveCompanyBody, void, void>();
export const handleActiveCompanyAction = createAsyncAction(
  `@companies/ACTIVE_COMPANY_REQUEST`,
  `@companies/ACTIVE_COMPANY_SUCCESS`,
  `@companies/ACTIVE_COMPANY_FAIL`,
)<ActiveCompanyBody, void, void>();
export const handleViewCompanyAction = createAsyncAction(
  `@verificationsByPartner/VIEW_COMPANY_REQUEST`,
  `@verificationsByPartner/VIEW_COMPANY_SUCCESS`,
  `@verificationsByPartner/VIEW_COMPANY_FAIL`,
)<CommonApiParam, ViewCompanyResponse, void>();

export const exportVerifications = createAsyncAction(
  `@verifications/EXPORT_VERIFICATIONS_REQUEST`,
  `@verifications/EXPORT_VERIFICATIONS_SUCCESS`,
  `@verifications/EXPORT_VERIFICATIONS_FAIL`,
)<CommonApiParam, any, void>();

export const getDetailVerifications = createAsyncAction(
  `@verifications/GET_DETAIL_VERIFICATION_BY_PARTNER_REQUEST`,
  `@verifications/GET_DETAIL_VERIFICATION_BY_PARTNER_SUCCESS`,
  `@verifications/GET_DETAIL_VERIFICATION_BY_PARTNER_FAIL`,
)<string, VerificationsDetail, void>();

export const getDetailCompany = createAsyncAction(
  `@companies/GET_DETAIL_COMPANY_REQUEST`,
  `@companies/GET_DETAIL_COMPANY_SUCCESS`,
  `@companies/GET_DETAIL_COMPANY_FAIL`,
)<string, CompanyDetail, void>();
