import authorizedRequest from 'helpers/request/authorized.request';
import { GenericData, GenericDataList } from 'models/common.model';
import { AccountModel } from 'models/store/account.model';
import { AccountListRequestPayload } from 'store/account/account.action';
import { PREFIX_API } from './request/config.endpoint';

export type AccountListResponse = GenericDataList<AccountModel>;

export function getAccountListApi(payload: AccountListRequestPayload) {
  return authorizedRequest.get<AccountListResponse, AccountListResponse>(
    `${PREFIX_API}/users`,
    { params: payload },
  );
}

export type AccountDetailResponse = GenericData<AccountModel>;

export function getAccountDetailApi(id: string) {
  return authorizedRequest.get<AccountDetailResponse, AccountDetailResponse>(
    `${PREFIX_API}/${id}/detail`,
  );
}
