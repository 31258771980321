export const LANGUAGES = {
  en: 'en_US',
};

export const I18N_NAMESPACES = {
  COMMON: 'common',
  HOME: 'home',
  LOGIN: 'login',
  VERIFICATION: 'verification',
  NOTIFICATION: 'notification',
};
