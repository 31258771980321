import { call, put, takeLatest } from '@redux-saga/core/effects';
import { getStatisticApi } from 'apis/companies.api';
import {
  activeCompanyApi,
  deactiveCompanyApi,
  getDetailVerificationdByPartnerApi,
  getListVerificationsByPartnerApi,
  viewCompanyApi,
} from 'apis/verifications-by-partner.api';
import { getMessageFromError } from 'helpers/common.helper';
import { toastError, toastSuccess } from 'helpers/notification.helper';
import {
  getDetailVerifications,
  getListVerifications,
  getStatisticCharts,
  handleActiveCompanyAction,
  handleDeactiveCompanyAction,
  handleViewCompanyAction,
} from './verifications.action';

function* handleGetListVerifications({
  payload,
}: ReturnType<typeof getListVerifications.request>) {
  try {
    const response: Await<ReturnType<
      typeof getListVerificationsByPartnerApi
    >> = yield getListVerificationsByPartnerApi(payload);

    yield put(getListVerifications.success(response.data));
  } catch (e) {
    yield put(getListVerifications.failure(e));
  }
}

function* getStatisticChartsSaga({
  payload,
}: ReturnType<typeof getStatisticCharts.request>) {
  try {
    const response: Await<ReturnType<typeof getStatisticApi>> = yield call(
      getStatisticApi,
      payload,
    );
    const { data } = response;
    yield put(getStatisticCharts.success(data));
  } catch (e) {
    yield put(getStatisticCharts.failure(e));
  }
}

function* handleGetDetailCompany({
  payload,
}: ReturnType<typeof getDetailVerifications.request>) {
  try {
    const response: Await<ReturnType<
      typeof getDetailVerificationdByPartnerApi
    >> = yield call(getDetailVerificationdByPartnerApi, payload);
    const { data } = response;
    yield put(getDetailVerifications.success(data));
  } catch (e) {
    yield put(getDetailVerifications.failure(e));
  }
}
function* handleDeactiveCompanyActionSaga({
  payload,
}: ReturnType<typeof handleDeactiveCompanyAction.request>) {
  try {
    const response: Await<ReturnType<typeof deactiveCompanyApi>> = yield call(
      deactiveCompanyApi,
      payload,
    );
    yield put(handleDeactiveCompanyAction.success(response?.data));
    toastSuccess('Company Deactivated');
    payload?.afterUpdate();
  } catch (error) {
    yield put(handleDeactiveCompanyAction.failure(getMessageFromError(error)));
    toastError(error);
  }
}
function* handleActiveCompanyActionSaga({
  payload,
}: ReturnType<typeof handleActiveCompanyAction.request>) {
  try {
    const response: Await<ReturnType<typeof activeCompanyApi>> = yield call(
      activeCompanyApi,
      payload,
    );
    yield put(handleActiveCompanyAction.success(response?.data));
    toastSuccess('Company Activated');
    payload?.afterUpdate();
  } catch (e) {
    yield put(handleActiveCompanyAction.failure(e));
    toastError(e);
  }
}
function* handleViewCompanyActionSaga({
  payload,
}: ReturnType<typeof handleViewCompanyAction.request>) {
  try {
    const response: Await<ReturnType<typeof viewCompanyApi>> = yield call(
      viewCompanyApi,
      payload,
    );
    yield put(handleViewCompanyAction.success(response?.data));
    payload?.afterCreate();
  } catch (error) {
    yield put(handleViewCompanyAction.failure(getMessageFromError(error)));
    toastError(error);
  }
}
export default function* verificationsByPartnerSaga() {
  yield takeLatest(getListVerifications.request, handleGetListVerifications);
  yield takeLatest(getDetailVerifications.request, handleGetDetailCompany);
  yield takeLatest(
    handleDeactiveCompanyAction.request,
    handleDeactiveCompanyActionSaga,
  );
  yield takeLatest(
    handleActiveCompanyAction.request,
    handleActiveCompanyActionSaga,
  );
  yield takeLatest(
    handleViewCompanyAction.request,
    handleViewCompanyActionSaga,
  );
  yield takeLatest(getStatisticCharts.request, getStatisticChartsSaga);
}
