import { GenericData } from 'models/common.model';
import { AccountModel } from 'models/store/account.model';
import { createAsyncAction } from 'typesafe-actions';

export interface AccountListSuccessPayload {
  data: Array<AccountModel>;
  totalItem: number;
  page: number;
  totalPage: number;
  pageSize: number;
}

export interface AccountListRequestPayload {
  content?: string;
  page?: number;
  pageSize?: number;
  select?: string;
  sort?: string;
  populations?: string;
  where?: string;
  pattern?: string;
  verifyStatus?: string;
  startDate?: number;
  endDate?: number;
}

export const getAccountListAction = createAsyncAction(
  `account/GET_LIST_ACCOUNT`,
  `account/GET_LIST_ACCOUNT_SUCCEEDED`,
  `account/GET_LIST_ACCOUNT_FAILED`,
)<AccountListRequestPayload, AccountListSuccessPayload, string>();

export type AccountDetailSuccessPayload = GenericData<AccountModel>;

export const getAccountDetailAction = createAsyncAction(
  `account/GET_DETAIL_ACCOUNT`,
  `account/GET_DETAIL_ACCOUNT_SUCCEEDED`,
  `account/GET_DETAIL_ACCOUNT_FAILED`,
)<string, AccountDetailSuccessPayload, string>();
