import { CredentialStoreModel } from 'models/store/credential.model';
import { createReducer } from 'typesafe-actions';
import {
  getListCredential,
  getDetailTemplate,
  deleteTemplate,
  createOfferAction,
  getListWalletInUser,
  clearListWalletAction,
  getListWallet,
  clearListWalletIdAction,
  clearDetailTemplateAction,
  updateCredentialDetail,
} from './credential.action';

const INITIAL_STATE: CredentialStoreModel = {
  loading: false,
  loadingCreateOffer: false,
  listCredential: null,
  offerDetail: undefined,
  verificationDetail: undefined,
  detailTemplate: undefined,
  listWalletInUser: undefined,
  listWallet: undefined,
};

const credentialReducer = createReducer<CredentialStoreModel>(INITIAL_STATE)
  .handleAction(getListCredential.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListCredential.success, (state, { payload }) => ({
    ...state,
    listCredential: payload,
    loading: false,
  }))
  .handleAction(getListCredential.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getDetailTemplate.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getDetailTemplate.success, (state, { payload }) => ({
    ...state,
    detailTemplate: payload,
    loading: false,
  }))
  .handleAction(getDetailTemplate.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getListWalletInUser.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListWalletInUser.success, (state, { payload }) => ({
    ...state,
    listWalletInUser: payload,
    loading: false,
  }))
  .handleAction(getListWalletInUser.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getListWallet.request, (state) => ({
    ...state,
  }))
  .handleAction(getListWallet.success, (state, { payload }) => {
    return {
      ...state,
      listWallet: payload,
    };
  })
  .handleAction(getListWallet.failure, (state) => ({
    ...state,
  }))
  .handleAction(deleteTemplate.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(deleteTemplate.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(deleteTemplate.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(createOfferAction.request, (state) => ({
    ...state,
    loadingCreateOffer: true,
  }))
  .handleAction(createOfferAction.success, (state, { payload }) => ({
    ...state,
    loadingCreateOffer: false,
    offerDetail: payload,
  }))
  .handleAction(createOfferAction.failure, (state) => ({
    ...state,
    loadingCreateOffer: false,
  }))
  .handleAction(clearListWalletAction, (state) => ({
    ...state,
    listWalletInUser: undefined,
  }))
  .handleAction(clearListWalletIdAction, (state) => ({
    ...state,
    listWallet: undefined,
  }))
  .handleAction(clearDetailTemplateAction, (state) => ({
    ...state,
    detailTemplate: undefined,
  }))
  .handleAction(updateCredentialDetail, (state, { payload }) => ({
    ...state,
    listCredential: {
      ...state.listCredential,
      data: state.listCredential.data.map((item) => {
        if (item._id === payload.id) {
          return {
            ...item,
            verifierSources: payload.value,
          };
        }
        return item;
      }),
    },
  }));

export default credentialReducer;
