import authorizedRequest from 'apis/request/authorizedRequest';
import { PREFIX_API, PREFIX_API_REPORT } from 'apis/request/config.endpoint';
import queryString from 'query-string';

import {
  ListRolePermissionResponse,
  listTreeRolePermission,
  ResponseUrlAndAction,
  UrlAndAction,
} from 'models/store/permission.model';
import CONFIG from 'config';
import { PREFIX_API_IAM } from './request/config.endpoint';
import { ListInternalRoleParams, RoleByUser } from './users.api';

export interface CreateMessageBlastBody {
  name: string;
  templateId: string;
  schedule?: number;
  selectReceivers?: string[];
  messageType: string;
  message: string;
  status: string;
}

export interface CreateMessageAutomationBody {
  name: string;
  templateId: string;
  triggers?: string[];
  delay?: DelayTime;
  numberOfMessages?: number;
  messageType?: string;
  message?: string;
  status?: string;
  timePeriod?: TimePeriod;
}
export interface CommonApiParam {
  startTime?: string | number;
  endTime?: string | number;
  id?: string;
  name?: string;
  page?: number;
  sort?: string;
  status?: string;
  pageSize?: number;
}
export interface DelayTime {
  delayTime: number;
  unit: string;
}

export interface TimePeriod {
  beginTime: number;
  endTime: number;
}
interface UserIds {
  userIds?: string[];
}
export interface FileResponse {
  link: string;
}
export const getRolePermissionApi = (params?: ListInternalRoleParams) => {
  return authorizedRequest.get<
    ListRolePermissionResponse,
    ListRolePermissionResponse
  >(`${PREFIX_API_IAM}/roles`, {
    params,
  });
};
export const getListRoleByInternalUserApi = (body: UserIds) => {
  return authorizedRequest.post<RoleByUser[], RoleByUser[]>(
    `${PREFIX_API_IAM}/grant-permissions`,
    body,
  );
};
export const createRolePermissionApi = (body: any) => {
  return authorizedRequest.post<any, any>(`${PREFIX_API_IAM}/roles`, body);
};

export const updateRolePermissionApi = (id: string, body: any) => {
  return authorizedRequest.patch<void>(`${PREFIX_API_IAM}/roles/${id}`, body);
};

export const getDetailTreePermissionApi = () => {
  return authorizedRequest.get<
    listTreeRolePermission[],
    listTreeRolePermission[]
  >(`${PREFIX_API_IAM}/permissions/group/tree`);
};

export const getDetailPermissionDetailApi = (id: string) => {
  return authorizedRequest.get<any, any>(`${PREFIX_API_IAM}/roles/${id}`);
};

export function getMineRoleApi() {
  return authorizedRequest.get<ResponseUrlAndAction, ResponseUrlAndAction>(
    `${PREFIX_API_IAM}/grant-permissions`,
  );
}

export const deletePermissionDetailApi = (id: string) => {
  return authorizedRequest.delete<any, any>(`${PREFIX_API_IAM}/roles/${id}`);
};
export const exportRolesApi = (params: CommonApiParam) => {
  const paramsString = queryString.stringify(params);
  return authorizedRequest.get<FileResponse>(
    `${PREFIX_API_REPORT}/roles/export-report-link?${paramsString}`,
  );
};
