import authorizedRequest from 'helpers/request/authorized.request';
import { GenericData, GenericDataList } from 'models/common.model';
import { Image, PhoneNumber } from 'models/store/verification.model';
import { ListVerificationParams } from 'store/verification/verification.action';
import { PREFIX_API, PREFIX_API_FILE } from './request/config.endpoint';

interface Verification {
  _id: string;
  user?: {
    phoneNumber?: PhoneNumber;
    id?: string;
  };
  streetNumber?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  dateCreated: string;
  dateUpdated: string;
  status?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  verifyStatus?: string;
  dateOfBirth?: string;
}

export type listVerificationResponse = GenericDataList<Verification>;

export function getListVerificationApi(payload: ListVerificationParams) {
  return authorizedRequest.get<
    listVerificationResponse,
    listVerificationResponse
  >(`${PREFIX_API}/verify-user`, {
    params: payload,
  });
}

interface DetailVerification {
  _id: string;
  user?: string;
  address?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  dateCreated: string;
  dateUpdated: string;
  status?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  verifyStatus?: string;
  dateOfBirth?: string;
  last4SSN?: string;
}

export type detailVerificationResponse = GenericData<DetailVerification>;

export function getDetailVerificationApi(id: string) {
  return authorizedRequest.get<
    detailVerificationResponse,
    detailVerificationResponse
  >(`${PREFIX_API}/verify-user/${id}/detail`);
}

export const uploadFileApi = (dataParams: FormData) =>
  authorizedRequest.post<Image[]>(`${PREFIX_API}/upload`, dataParams);

export const getLinkUrlFileApi = (type: string) =>
  authorizedRequest.get<any>(
    `${PREFIX_API_FILE}/uploads/post-image-signature/${type}`,
  );

export const getLinkUrlFileVideoApi = (type: string) =>
  authorizedRequest.get<any>(
    `${PREFIX_API_FILE}/uploads/post-video-signature/${type}`,
  );

export type appceptVerificationResponse = GenericData<DetailVerification>;

export function AcceptVerificationApi(id: string) {
  return authorizedRequest.post<
    appceptVerificationResponse,
    appceptVerificationResponse
  >(`${PREFIX_API}/verify-user/${id}/approve`);
}

export type declinedVerificationResponse = GenericData<DetailVerification>;

export function DeclinedVerificationApi(id: string) {
  return authorizedRequest.post<
    declinedVerificationResponse,
    declinedVerificationResponse
  >(`${PREFIX_API}/verify-user/${id}/decline`);
}

export const uploadFileBatchApi = (dataParams: FormData) =>
  authorizedRequest.post<any>(`${PREFIX_API}/user/import`, dataParams);
