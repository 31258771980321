import {
  IListMessageParams,
  IListMessageResponse,
  MessageListFailResponse,
} from 'apis/message.api';
import { createAsyncAction } from 'typesafe-actions';

export const getListMessage = createAsyncAction(
  `@custom-message/GET_lIST_MESSAGE_REQUEST`,
  `@custom-message/GET_lIST_MESSAGE_SUCCESS`,
  `@custom-message/GET_lIST_MESSAGE_FAIL`,
)<IListMessageParams, IListMessageResponse, MessageListFailResponse>();
