import { GenericData } from 'models/common.model';
import { NotificationModel } from 'models/store/notification.model';
import { createAsyncAction } from 'typesafe-actions';

interface listNotificationParams {
  totalItem?: number;
  page?: number;
  totalPage?: number;
  pageSize?: number;
}

export interface ListNotificationSuccessPayload {
  data: NotificationModel[];
  totalItem: number;
  page: number;
  totalPage: number;
  pageSize: number;
  totalUnread: number;
}

export const getListNotificationAction = createAsyncAction(
  `notification/GET_LIST_NOTIFICATION`,
  `notification/GET_LIST_NOTIFICATION_SUCCEEDED`,
  `notification/GET_LIST_NOTIFICATION_FAILED`,
)<listNotificationParams, ListNotificationSuccessPayload, string>();

export type readNotificationSuccessPayload = GenericData<NotificationModel>;

export const readNotificationAction = createAsyncAction(
  `notification/READ_NOTIFICATION`,
  `notification/READ_NOTIFICATION_SUCCEEDED`,
  `notification/READ_NOTIFICATION_FAILED`,
)<string, readNotificationSuccessPayload, string>();
