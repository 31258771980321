import {
  ListRolePermissionResponse,
  listTreeRolePermission,
  UrlAndAction,
} from 'models/store/permission.model';
import { createAsyncAction } from 'typesafe-actions';

export interface IListPermissionParams {
  sort?: string;
  pageSize?: number;
  page?: number;
}

interface objectMessage {
  field: string;
  message: Array<string>;
}

interface NetworkFailResponse {
  message: Array<objectMessage>;
}

export const getListTreePermission = createAsyncAction(
  `@network/GET_LIST_TREE_PERMISSION_REQUEST`,
  `@network/GET_LIST_TREE_PERMISSION_SUCCESS`,
  `@network/GET_LIST_TREE_PERMISSION_FAIL`,
)<IListPermissionParams, listTreeRolePermission[], NetworkFailResponse>();

export const getListRolePermission = createAsyncAction(
  `@network/GET_LIST_ROLE_PERMISSION_REQUEST`,
  `@network/GET_LIST_ROLE_PERMISSION_SUCCESS`,
  `@network/GET_LIST_ROLE_PERMISSION_FAIL`,
)<IListPermissionParams, ListRolePermissionResponse, NetworkFailResponse>();

export const getRoleUrlAndAction = createAsyncAction(
  'role_permissions/GET_ROLE_URL_ACTION_REQUEST',
  'role_permissions/GET_ROLE_URL_ACTION_SUCCESS',
  'role_permissions/GET_ROLE_URL_ACTION_FAIL',
)<void, { isRoot: boolean; list: Array<UrlAndAction> }, void>();
