import authorizedRequest from 'helpers/request/authorized.request';
import { GenericData, GenericDataList } from 'models/common.model';

interface ListNotificationPayload {
  totalItem?: number;
  page?: number;
  totalPage?: number;
  pageSize?: number;
}

interface Notification {
  _id: string;
  receive: string;
  types: string[];
  isRead: boolean;
  email: {
    _id: string;
    sender: string;
    receive: string;
    type: string;
  };
  userSender: {
    phoneNumber?: {
      phone?: string;
      dialCode?: string;
      fullPhone?: string;
    };
    _id: string;
    dateOfBirth?: string;
    firstName?: string;
    lastName?: string;
    verifiedDate: string;
  };
  contentMail: string;
  requestManual?: string;
  dateCreated: string;
  dateUpdated: string;
}

export type listNotificationResponse = GenericDataList<Notification>;

export function getListNotificationApi(params: ListNotificationPayload) {
  return authorizedRequest.get<
    listNotificationResponse,
    listNotificationResponse
  >('/notifications', {
    params,
  });
}

export type readNotificationResponse = GenericData<Notification>;

export function readNotificationApi(id: string) {
  return authorizedRequest.get<
    readNotificationResponse,
    readNotificationResponse
  >(`/notification/${id}`);
}

export type deleteNotificationResponse = GenericData<Notification>;

export function deleteNotificationApi(id: string) {
  return authorizedRequest.delete<
    deleteNotificationResponse,
    deleteNotificationResponse
  >(`/notification/${id}`);
}

interface DeleteNotification {
  n: number;
  ok: number;
}

export type deleteAllNotificationResponse = GenericData<DeleteNotification>;

export function deleteAllNotificationApi() {
  return authorizedRequest.delete<
    deleteAllNotificationResponse,
    deleteAllNotificationResponse
  >(`/notifications`);
}
