import i18n from 'i18next';
import { toastError, toastSuccess } from './notification.helper';

export function getMessageFromError(error: any) {
  if (error === 401) {
    return i18n.t('common:sessionExpired');
  }
  if (typeof error === 'string') {
    return error;
  }
  if (error?.response) {
    return Array.isArray(error.response?.data?.message)
      ? error.response?.data?.message[0]
      : error.response?.data?.errors?.message ||
          error.response?.data?.message ||
          error.message;
  }

  return (
    error?.message || error?.errors?.message || i18n.t('common:unknownError')
  );
}

export function isDevelopment() {
  const appStage = process.env.REACT_APP_STAGE
    ? process.env.REACT_APP_STAGE.trim()
    : 'production';
  return appStage === 'local' || appStage === 'dev';
}
export function isProduction() {
  const appStage = process.env.REACT_APP_STAGE.trim();
  return appStage === 'production';
}

export const formatPhoneNumber = (
  dialCodeString: string,
  phoneNumberString: string,
) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3,10})$/);
  const matchUK = cleaned.match(/^(\d{4})(\d{3,10})$/);

  if (match && matchUK) {
    if (!dialCodeString.includes('+44')) {
      return `${dialCodeString} (${match[1]}) ${match[2]}-${match[3]}`;
    }
    return `${dialCodeString} ${matchUK[1]} ${matchUK[2]}`;
  }
  return '';
};

export const formatPhoneNumberNoCode = (
  dialCodeString: string,
  phoneNumberString: string,
) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3,10})$/);
  const matchUK = cleaned.match(/^(\d{4})(\d{3,10})$/);

  if (match && matchUK) {
    if (!dialCodeString.includes('+44')) {
      return `${dialCodeString} (${match[1]}) ${match[2]}-${match[3]}`;
    }
    return `${dialCodeString} ${matchUK[1]} ${matchUK[2]}`;
  }
  return '';
};

export function copyToClipboard(value: string, onCopySuccess?: () => void) {
  try {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (onCopySuccess) {
      onCopySuccess();
    }
    toastSuccess('Copied to Clipboard');
  } catch (e) {
    toastError('An error occurred while trying to copy to Clipboard');
  }
}

export const renderUrlImage = (cdn: string, size?: any, objectImage?: any) => {
  if (!objectImage) {
    return 'not-found';
  }
  return size ? `${cdn}${objectImage}/${size}` : `${cdn}${objectImage}`;
};
