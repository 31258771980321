/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
import {
  GetProfileResponse,
  ListInternalUserParams,
  ListInternalUserResponse,
} from './../../apis/users.api';
import { createAsyncAction } from 'typesafe-actions';

export const getListInternalUserAction = createAsyncAction(
  `users/GET_LIST_INTERNAL_USER`,
  `users/GET_LIST_INTERNAL_USER_SUCCEEDED`,
  `users/GET_LIST_INTERNAL_USER_FAILED`,
)<ListInternalUserParams, ListInternalUserResponse, string>();

export const getProfileUserAction = createAsyncAction(
  `@user-profile/GET_PROFILE_INTERNAL_USER_REQUEST`,
  `@user-profile/GET_PROFILE_INTERNAL_USER_SUCCESS`,
  `@user-profile/GET_PROFILE_INTERNAL_USER_FAIL`,
)<void, GetProfileResponse, string>();
