class TokenManager {
  token: string = '';

  showMessageExpiredOnce: boolean = true;

  logout: Array<() => void> = [];

  setToken = (value: string) => {
    this.token = value;
  };

  setLogout = (method: () => void) => {
    this.logout = [method];
  };

  doLogout = () => {
    this.token = '';
    this.logout.forEach((method: () => void) => method());
  };

  setShowMessageExpiredOnce = (v: boolean) => {
    this.showMessageExpiredOnce = v;
  };
}

const tokenManger = new TokenManager();

export default tokenManger;
