/* eslint-disable max-len */
import CONFIG from 'config';
import CommonImage from 'assets/images/common';

export const PHONE_DEFAULT = '999 999 999';
export const MANAGEMENT_TAB = 'management_tab';
export const OTP_CODE_DEFAULT = '000000';
export const TIMEOUT_DEFAULT = 180;
// eslint-disable-next-line import/no-mutable-exports
const DIAL_CODE_DEFAULT = CONFIG.NAME === 'production' ? '+1' : '+84';
export const GET_NUMBER_REGEX = /\d+/g;
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,}){1,}$/;
export const IMAGE_EXTENSIONS = 'image/*';
export const IMAGE_VIDEO_EXTENSIONS = 'image/*,video/*';
export const EXCEL_EXTENSIONS =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const MAX_SIZE_FILE = 5242880;
export const MAX_SIZE_VIDEO = 10485760;
// eslint-disable-next-line max-len
export const URL_HTTPS_REGEX = /^(?:(?:(?:https|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
// eslint-disable-next-line no-useless-escape
export const ISSUE_LINK_REGEX = /^https\:\/\/(app|app-v2)(\.dev|\.staging)?.dentity.com\/save-credential\/[a-z0-9]{24,24}/;
export { DIAL_CODE_DEFAULT };

export const AUTOMATION_MESSAGE = 'automation';
export const BLAST_MESSAGE = 'blast_text';

export const MESSAGE_RECEIVERS = [
  {
    id: 'downloaded',
    label: 'User who has downloaded this credential',
  },
  {
    id: 'verify_via_qr',
    label: 'User who has VERIFIED this credential via QR Code',
  },
  {
    id: 'verify_via_oidc',
    label: 'User who has VERIFIED this credential via OIDC',
  },
  {
    id: 'verify_via_scanning_device',
    label: 'User who has VERIFIED this credential via Scanning Device',
  },
];

export const MESSAGE_TRIGGER = [
  {
    id: 'downloaded',
    label: 'When User downloads a credential',
  },
  {
    id: 'verify_via_qr',
    label: 'When User verifies a credential via QR Code',
  },
  {
    id: 'verify_via_oidc',
    label: 'When User verifies a credential via OIDC',
  },
  {
    id: 'verify_via_scanning_device',
    label: 'When User verifies a credential via Scanning Device',
  },
];

export const UNIT_TIME = [
  {
    id: 'minutes',
    label: 'Minutes',
  },
  {
    id: 'hours',
    label: 'Hours',
  },
  {
    id: 'days',
    label: 'Days',
  },
  {
    id: 'weeks',
    label: 'Weeks',
  },
];

export const COUNTRY_ARR = [
  { id: '1', name: 'United States', value: '+1', img: CommonImage.icUS },
  { id: '2', name: 'Canada', value: '+1', img: CommonImage.icCanada },
  { id: '11', name: 'Argentina', value: '+54', img: CommonImage.icAR },
  { id: '12', name: 'Australia', value: '+61', img: CommonImage.icAU },
  { id: '13', name: 'Brazil', value: '+55', img: CommonImage.icBR },
  { id: '14', name: 'Colombia', value: '+57', img: CommonImage.icCO },
  { id: '3', name: 'France', value: '+33', img: CommonImage.icFR },
  { id: '4', name: 'Germany', value: '+49', img: CommonImage.icDE },
  { id: '15', name: 'India', value: '+91', img: CommonImage.icIN },
  { id: '16', name: 'Israel', value: '+972', img: CommonImage.icIL },
  { id: '17', name: 'Japan', value: '+81', img: CommonImage.icJP },
  { id: '5', name: 'Mexico', value: '+52', img: CommonImage.icMX },
  { id: '18', name: 'Netherlands', value: '+31', img: CommonImage.icNL },
  { id: '19', name: 'Poland', value: '+48', img: CommonImage.icPL },
  { id: '20', name: 'South Africa', value: '+27', img: CommonImage.icZA },
  { id: '21', name: 'South Korea', value: '+82', img: CommonImage.icKR },
  { id: '6', name: 'Spain', value: '+34', img: CommonImage.icES },
  { id: '22', name: 'Sweden', value: '+46', img: CommonImage.icSE },
  { id: '23', name: 'Switzerland', value: '+41', img: CommonImage.icCH },
  { id: '7', name: 'Thailand', value: '+66', img: CommonImage.icTH },
  { id: '24', name: 'Turkey', value: '+90', img: CommonImage.icTR },
  { id: '9', name: 'United Kingdom', value: '+44', img: CommonImage.icUK },
  { id: '10', name: 'Vietnam', value: '+84', img: CommonImage.icVN },
];
export const CANADA_AREA_CODE = [
  '368',
  '403',
  '587',
  '780',
  '825',
  '236',
  '250',
  '604',
  '672',
  '778',
  '204',
  '431',
  '428',
  '506',
  '709',
  '879',
  '867',
  '782',
  '902',
  '226',
  '249',
  '289',
  '343',
  '365',
  '416',
  '437',
  '519',
  '548',
  '613',
  '647',
  '705',
  '742',
  '807',
  '905',
  '354',
  '367',
  '418',
  '438',
  '450',
  '514',
  '579',
  '581',
  '819',
  '873',
  '306',
  '474',
  '639',
  '600',
  '622',
  '800',
  '833',
  '844',
  '855',
  '866',
  '877',
  '888',
];

export enum ActionPermission {
  ACCESS = 'access',
  VIEW = 'view',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  EXPORT = 'export',
}

export enum TYPE_SIZE_IMAGE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  ORIGIN = 'origin',
}
export const maskPhoneNumber = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const maskPhoneNumberUK = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,

  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const MESSAGE_EXPORT_LIMIT =
  'Maximum for each time export is 10.000 records';

export const VERIFICATION_IDENTITY = [
  {
    value: 3,
    label: 'Visual ID verification',
  },
  {
    value: 4,
    label: 'Visual ID verification and NIST 800-63-3 Compliance',
  },
  {
    value: 5,
    label: 'Visual ID verification and AML check',
  },
];

export const TEST_INPUT_NUMBER_PRICE = /^\d{0,15}(?:\.\d{1,2})?$/;
