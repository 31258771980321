import { createReducer } from 'typesafe-actions';

import { NetworkStoreModel } from 'models/store/network.model';
import { getListNetwork, getListVerifyRequest } from './network.action';

const INITIAL_STATE: NetworkStoreModel = {
  loading: false,
  listNetwork: null,
  listVerify: null,
  detailNetwork: undefined,
};

const networkReducer = createReducer<NetworkStoreModel>(INITIAL_STATE)
  .handleAction(getListNetwork.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListNetwork.success, (state, { payload }) => ({
    ...state,
    listNetwork: payload,
    loading: false,
  }))
  .handleAction(getListNetwork.failure, (state) => ({
    ...state,
    loading: false,
  }))

  .handleAction(getListVerifyRequest.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListVerifyRequest.success, (state, { payload }) => ({
    ...state,
    listVerify: payload,
    loading: false,
  }))
  .handleAction(getListVerifyRequest.failure, (state) => ({
    ...state,
    loading: false,
  }));

export default networkReducer;
