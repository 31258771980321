import { createReducer } from 'typesafe-actions';
import { AccountStoreState } from '../../models/store/account.model';
import { getAccountDetailAction, getAccountListAction } from './account.action';

const initState: AccountStoreState = {
  data: undefined,
  dataDetail: undefined,
  totalPage: 0,
  totalItem: 0,
  pageSize: 0,
  page: 0,
  loading: false,
};

const accountReducer = createReducer<AccountStoreState>(initState)
  .handleAction(getAccountListAction.request, (state) => {
    return { ...state, data: undefined, loading: true };
  })
  .handleAction(getAccountListAction.success, (state, { payload }) => {
    return {
      ...state,
      data: payload.data,
      totalItem: payload.totalItem,
      totalPage: payload.totalPage,
      pageSize: payload.pageSize,
      page: payload.page,
      loading: false,
    };
  })
  .handleAction(getAccountListAction.failure, (state, { payload }) => {
    return { ...state, loading: false, error: payload };
  })
  .handleAction(getAccountDetailAction.request, (state) => {
    return { ...state, dataDetail: undefined, loading: true };
  })
  .handleAction(getAccountDetailAction.success, (state, { payload }) => {
    return { ...state, dataDetail: payload.data, loading: false };
  })
  .handleAction(getAccountDetailAction.failure, (state, { payload }) => {
    return { ...state, loading: false, error: payload };
  });

export default accountReducer;
