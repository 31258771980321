/* eslint-disable prettier/prettier */
import { createAsyncAction } from 'typesafe-actions';

import {
  CategoryListFailResponse,
  IListCategoryParams,
  IListListingParams,
  ListCategoryResponse,
  ListListingResponse,
} from 'apis/marketplace.api';

export const getListCategory = createAsyncAction(
  `@marketplace/GET_lIST_CATEGORY_REQUEST`,
  `@marketplace/GET_lIST_CATEGORY_SUCCESS`,
  `@marketplace/GET_lIST_CATEGORY_FAIL`,
)<IListCategoryParams, ListCategoryResponse, CategoryListFailResponse>();

export const getListListing = createAsyncAction(
  `@marketplace/GET_lIST_LISTING_REQUEST`,
  `@marketplace/GET_lIST_LISTING_SUCCESS`,
  `@marketplace/GET_lIST_lISTING_FAIL`,
)<IListListingParams, ListListingResponse, CategoryListFailResponse>();

export const getListListingBusiness = createAsyncAction(
  `@marketplace/GET_lIST_LISTING_BUSINESS_REQUEST`,
  `@marketplace/GET_lIST_LISTING_BUSINESS_SUCCESS`,
  `@marketplace/GET_lIST_lISTING_BUSINESS_FAIL`,
)<IListListingParams, ListListingResponse, CategoryListFailResponse>();
