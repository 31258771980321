import authorizedRequest from 'apis/request/authorizedRequest';
import { PREFIX_API, PREFIX_API_REPORT } from 'apis/request/config.endpoint';
import {
  INetworkData,
  IVerifyData,
  ListVerifyResponse,
  Image,
} from 'models/store/verify.model';
import {
  IExportVerifyParams,
  IListVerifyParams,
} from 'store/verify/verify.action';

import queryString from 'query-string';
import authorizedOIDCRequest from 'helpers/request/authorized-oidc.request';
import downloadFileRequestOIDC from './request/downloadFileRequestOIDC';

export interface CreateVerifyBody {
  appName: string;
  redirectUri: string;
  logoutLink: string;
  templateIds: any;
  logoId: string;
  verifierName: string;
  privacyPolicyLink: string;
}

export const getDetailVerifyApi = (id: string) => {
  return authorizedOIDCRequest.get<IVerifyData, IVerifyData>(
    `admin/oidc/app/${id}`,
  );
};

export const getListVerifyApi = (params: IExportVerifyParams) => {
  return authorizedOIDCRequest.get<ListVerifyResponse>(`admin/oidc/app`, {
    params,
  });
};

export const exportVerifyApi = (dataParams: Partial<IExportVerifyParams>) => {
  const params = queryString.stringify(dataParams);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/oidc/apps/export-report-link?${params}`,
  );
};

export const createVerifyApi = (body: CreateVerifyBody) => {
  return authorizedOIDCRequest.post<IVerifyData>(`admin/oidc/app`, body);
};

export const uploadFileApiOIDC = (dataParams: FormData) =>
  authorizedRequest.post<Image[]>(`${PREFIX_API}/upload`, dataParams);

export const deleteVerifyApi = (id: string) => {
  return authorizedOIDCRequest.delete<void>(`admin/oidc/app/${id}`);
};
