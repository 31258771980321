import { RoleContext } from 'contexts/RoleContext';
import { useContext } from 'react';

export default function useMineRoleContext() {
  const context = useContext(RoleContext);

  if (!context) {
    throw new Error('Info context hook is not used correctly');
  }

  return context;
}
