import React, { FC, ComponentClass } from 'react';

import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ROUTES from 'constants/routes.constant';

interface WithAuthOptions {
  needAuth?: boolean;
  unMatchingRedirect?: string;
}

export default function withAuth<Props = any>(
  Wrapped: FC | ComponentClass,
  customOptions?: WithAuthOptions,
) {
  const options = { needAuth: true, ...customOptions };

  if (!options.unMatchingRedirect) {
    options.unMatchingRedirect = options.needAuth
      ? ROUTES.AUTH.LOGIN
      : ROUTES.MAIN.HOME;
  }
  const WithAuth: FC<Props> = (props: any) => {
    const isAuth = useSelector((state) => !!state.auth.token);
    if (options.needAuth !== isAuth) {
      return (
        <Redirect
          to={{
            pathname: options.unMatchingRedirect,
          }}
          push={true}
        />
      );
    }
    return <Wrapped {...props} />;
  };

  return WithAuth;
}
