import { ICreateVerification, IStatisticData } from 'apis/verifications.api';
import {
  AvatarType,
  CommonApiParam,
  CreateVerification,
  ListVerificationsResponse,
  VerificationsDetail,
} from 'models/store/verifications.model';
import { createAsyncAction } from 'typesafe-actions';

interface objectMessage {
  field: string;
  message: Array<string>;
}

interface LoginFailResponse {
  message: Array<objectMessage>;
}
export const getListVerifications = createAsyncAction(
  `@verifications/GET_LIST_VERIFICATIONS_REQUEST`,
  `@verifications/GET_LIST_VERIFICATIONS_SUCCESS`,
  `@verifications/GET_LIST_VERIFICATIONS_FAIL`,
)<CommonApiParam, ListVerificationsResponse, LoginFailResponse>();

export const createVerifications = createAsyncAction(
  `@verifications/CREATE_VERIFICATIONS_REQUEST`,
  `@verifications/CREATE_VERIFICATIONS_SUCCESS`,
  `@verifications/CREATE_VERIFICATIONS_FAIL`,
)<CreateVerification, ICreateVerification, void>();

export const exportVerifications = createAsyncAction(
  `@verifications/EXPORT_VERIFICATIONS_REQUEST`,
  `@verifications/EXPORT_VERIFICATIONS_SUCCESS`,
  `@verifications/EXPORT_VERIFICATIONS_FAIL`,
)<CommonApiParam, void, void>();

export const getStatisticCharts = createAsyncAction(
  `@verifications/GET_STATISTIC_REQUEST`,
  `@verifications/GET_STATISTIC_SUCCESS`,
  `@verifications/GET_STATISTIC_FAIL`,
)<CommonApiParam, IStatisticData, void>();

export const deleteVerificationUserAction = createAsyncAction(
  `@verifications/DELETE_VERIFICATION_REQUEST`,
  `@verifications/DELETE_VERIFICATION_SUCCESS`,
  `@verifications/DELETE_VERIFICATION_FAIL`,
)<{ id: string; handleSuccess?: () => void }, void, void>();

export const declineVerificationUserAction = createAsyncAction(
  `@verifications/DECLINE_VERIFICATION_REQUEST`,
  `@verifications/DECLINE_VERIFICATION_SUCCESS`,
  `@verifications/DECLINE_VERIFICATION_FAIL`,
)<{ id: string; handleSuccess?: () => void }, void, void>();

export const acceptVerificationUserAction = createAsyncAction(
  `@verifications/ACCEPT_VERIFICATION_REQUEST`,
  `@verifications/ACCEPT_VERIFICATION_SUCCESS`,
  `@verifications/ACCEPT_VERIFICATION_FAIL`,
)<{ id: string; handleSuccess?: () => void }, void, void>();

export const getDetailVerifications = createAsyncAction(
  `@verifications/GET_DETAIL_VERIFICATION_REQUEST`,
  `@verifications/GET_DETAIL_VERIFICATION_SUCCESS`,
  `@verifications/GET_DETAIL_VERIFICATION_FAIL`,
)<string, VerificationsDetail, void>();

export const uploadFileActions = createAsyncAction(
  `@credentials/UPLOAD_FILE_ACTIONS`,
  `@credentials/UPLOAD_FILE_ACTIONS_SUCCESS`,
  `@credentials/UPLOAD_FILE_ACTIONS_FAIL`,
)<FormData, AvatarType, void>();
