import queryString from 'query-string';
import {
  CommonApiParam,
  ListCompaniesResponse,
  CompanyDetail,
  GetStatisticResponsive,
  ListWebsitesResponse,
  UpdatePlaidConfig,
  IStatus,
  IPlaidConfig,
  UpdateDentityConfig,
  IVerifyDialConfig,
  UpdateFaceTecConfig,
} from 'models/store/companies.model';
import authorizedRequest from 'apis/request/authorizedRequest';
import { PREFIX_API, PREFIX_API_REPORT } from 'apis/request/config.endpoint';
import { DeactiveCompanyBody } from 'models/store/verifications-by-partner.model';
import { DataList } from '../models/common.model';
import localRequest from './request/localRequest';

export const getListCompaniesApi = (dataParams: CommonApiParam) => {
  return authorizedRequest.get<ListCompaniesResponse>(`${PREFIX_API}/partner`, {
    params: dataParams,
  });
};
export const getDetailCompanyApi = (data: string) =>
  authorizedRequest.get<CompanyDetail>(`${PREFIX_API}/partner/${data}`);

export const getListWebsitesByPartnerIdApi = (
  dataParams: CommonApiParam,
  id: string,
) => {
  return authorizedRequest.get<ListWebsitesResponse>(
    `${PREFIX_API}/${id}/website`,
    {
      params: dataParams,
    },
  );
};
export const getStatisticApi = (dataDate: CommonApiParam) => {
  return authorizedRequest.get<GetStatisticResponsive>(
    `${PREFIX_API}/verification/statistic`,
    {
      params: dataDate,
    },
  );
};

export const exportCompaniesApi = (params: CommonApiParam) => {
  const paramsString = queryString.stringify(params);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/partners/export-report-link?${paramsString}`,
  );
};

export function deactiveProgrammaticApi(body: DeactiveCompanyBody) {
  return authorizedRequest.patch(`${PREFIX_API}/programmatic/deactivate`, body);
}
export function updateStatusWebsiteApi(id: string, body: IStatus) {
  return authorizedRequest.patch(`${PREFIX_API}/website/${id}/status`, body);
}
export function updatePlaidWebsiteApi(body: UpdatePlaidConfig) {
  return authorizedRequest.post<UpdatePlaidConfig>(
    `${PREFIX_API}/website/idv-setting`,
    body,
  );
}
export function updateDentityWebsiteApi(body: UpdateDentityConfig) {
  return authorizedRequest.post<UpdateDentityConfig>(
    `${PREFIX_API}/website/dentity-data`,
    body,
  );
}
export function updateFaceTecWebsiteApi(body: UpdateFaceTecConfig) {
  return authorizedRequest.patch<UpdateDentityConfig>(
    `${PREFIX_API}/website/face-scan-config`,
    body,
  );
}
export function getIDVType() {
  return authorizedRequest.get<IPlaidConfig>(`${PREFIX_API}/app-settings`);
}
export function getVerifyDialType() {
  return authorizedRequest.get<IVerifyDialConfig[]>(
    `core/api/v1/app-settings/verify-dial`,
  );
}

export function getVerifyFaceTec() {
  return authorizedRequest.get<any>(`core/api/v1/app-settings/verify-face`);
}
export const deleteWebsiteById = (id: string) => {
  return authorizedRequest.delete<void>(`${PREFIX_API}/website/${id}`);
};

export function reactiveProgrammaticApi(body: DeactiveCompanyBody) {
  return authorizedRequest.patch(`${PREFIX_API}/programmatic/reactivate`, body);
}

export interface IUpdateWallet {
  partner: {
    _id: string;
    email: string;
    businessName: string;
  };
  user: string;
  walletId: string;
  _id: string;
}
export interface IUpdateWalletResponse {
  data: DataList<IUpdateWallet>;
}
export function getListUpgradeWallet(dataParams: CommonApiParam) {
  return authorizedRequest.get<IUpdateWalletResponse, IUpdateWalletResponse>(
    `${PREFIX_API}/wallets/upgrade-wallet`,
    {
      params: dataParams,
    },
  );
}

export function UpgradeWallet(body: { walletIds: string[] }) {
  return authorizedRequest.post<IUpdateWalletResponse, IUpdateWalletResponse>(
    `${PREFIX_API}/wallets/upgrade-wallet`,
    body,
  );
}
