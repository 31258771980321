import { call, put, takeLatest } from 'redux-saga/effects';
import { getMessageFromError } from 'helpers/common.helper';
import { toastError } from 'helpers/toast.helper';
import {
  getAccountListApi,
  AccountListResponse,
  getAccountDetailApi,
  AccountDetailResponse,
} from 'apis/account.api';
import { getAccountDetailAction, getAccountListAction } from './account.action';

function* handleGetAcountList({
  payload,
}: ReturnType<typeof getAccountListAction.request>) {
  try {
    const response: AccountListResponse = yield call(
      getAccountListApi,
      payload,
    );
    /* const result = response.data.data.map((item) => {
      return {
        phoneNumber: {
          phone: item.phoneNumber.phone,
          dialCode: item.phoneNumber.dialCode,
        },
        // addressDetail: {
        //   streetNumber: item?.addressDetail?.streetNumber,
        //   street: item?.addressDetail?.street,
        //   city: item?.addressDetail?.city,
        //   state: item?.addressDetail?.state,
        //   country: item?.addressDetail?.country,
        //   zip: item?.addressDetail?.zip,
        // },
        // tagRegisterByRequest: item.tagRegisterByRequest,
        // verifyFailedCount: item.verifyFailedCount,
        // timeRequestOTP: item.timeRequestOTP,
        // isVerifyChangePhone: item.isVerifyChangePhone,
        _id: item._id,
        dateCreated: item.dateCreated,
        dateUpdated: item.dateUpdated,
        status: item.status,
        firstName: item.firstName,
        lastName: item.lastName,
        verifyStatus: item.verifyStatus,
        dateOfBirth: item.dateOfBirth,
      };
    }); */
    yield put(
      getAccountListAction.success({
        data: response.data.data,
        totalItem: response.data.totalItem,
        page: response.data.page,
        totalPage: response.data.totalPage,
        pageSize: response.data.pageSize,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(getAccountListAction.failure(getMessageFromError(error)));
  }
}

function* handleGetDetailAccount({
  payload,
}: ReturnType<typeof getAccountDetailAction.request>) {
  try {
    const response: AccountDetailResponse = yield call(
      getAccountDetailApi,
      payload,
    );
    yield put(
      getAccountDetailAction.success({
        data: response.data,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(getAccountDetailAction.failure(getMessageFromError(error)));
  }
}

export default function* accountSaga() {
  yield takeLatest(getAccountListAction.request, handleGetAcountList);
  yield takeLatest(getAccountDetailAction.request, handleGetDetailAccount);
}
