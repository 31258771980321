import {
  INetworkData,
  ListNetworkResponse,
  ListVerifyRequestResponse,
} from 'models/store/network.model';
import { createAsyncAction } from 'typesafe-actions';

export interface IListNetworkParams {
  sort?: string;
  pageSize?: number;
  page?: number;
}

export interface IExportNetworkParams {
  status: string;
  startDate: number;
  endDate: number;
  dateString: string;
  page: number;
  sort: string;
  credentialIssuer: string;
  credentialType: string;
  holderFullName: string;
  templateName: string;
  pageSize: number;

  phoneSender?: string;
  phoneReceive?: string;
  receiverId?: string;
  totalRequestsSent?: string;
  totalRequestsReceived?: string;
  receiverDial?: string;
}

interface objectMessage {
  field: string;
  message: Array<string>;
}

interface NetworkFailResponse {
  message: Array<objectMessage>;
}

export const getListNetwork = createAsyncAction(
  `@network/GET_LIST_NETWORK_REQUEST`,
  `@network/GET_LIST_NETWORK_SUCCESS`,
  `@network/GET_LIST_NETWORK_FAIL`,
)<IListNetworkParams, ListNetworkResponse, NetworkFailResponse>();

export const getListVerifyRequest = createAsyncAction(
  `@network/GET_LIST_VERIFY_REQUEST`,
  `@network/GET_LIST_VERIFY_SUCCESS`,
  `@network/GET_LIST_VERIFY_FAIL`,
)<IListNetworkParams, ListVerifyRequestResponse, NetworkFailResponse>();
