import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
} from 'axios';
import moment from 'moment-timezone';
import { stringify } from 'query-string';

import CONFIG from 'config';

const CANCEL = '@@redux-saga/CANCEL_PROMISE';

const { CancelToken } = axios;

export interface PromiseWithCancel<R> extends Promise<R> {
  [CANCEL]?: () => void;
}

interface CustomizationAxiosInstance extends AxiosInstance {
  [key: string]: any;
}

export default class Request {
  api: CustomizationAxiosInstance;

  constructor(BASE_URL: string = CONFIG.BASE_URL) {
    this.api = axios.create({
      withCredentials: false,
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        'x-timezone': moment.tz.guess(),
      },
      paramsSerializer: (params) => {
        return stringify(params, { arrayFormat: 'comma' });
      },
    });
  }

  configRequestMethod = <R>(
    url: string,
    config: AxiosRequestConfig = {},
    method: string,
    rawResponse?: boolean,
    body?: any,
  ) => {
    let cancel: Canceler;
    const apiConfig = {
      ...config,
      params: {
        ...config.params,
      },
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };

    const request: PromiseWithCancel<R> = (body
      ? this.api[method](url, body, apiConfig)
      : this.api[method](url, apiConfig)
    )
      .then((res: AxiosResponse<any>) => (rawResponse ? res : res.data))
      .catch((e: AxiosError<any>) => {
        throw e;
      });
    request[CANCEL] = () => cancel();
    return request;
  };

  get = <T = any, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig = {},
    rawResponse?: boolean,
  ) => {
    return this.configRequestMethod<R>(url, config, 'get', rawResponse);
  };

  post = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {},
    rawResponse?: boolean,
  ) => {
    return this.configRequestMethod<R>(url, config, 'post', rawResponse, body);
  };

  put = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {},
    rawResponse?: boolean,
  ) => {
    return this.configRequestMethod<R>(url, config, 'put', rawResponse, body);
  };

  patch = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {},
    rawResponse?: boolean,
  ) => {
    return this.configRequestMethod<R>(url, config, 'patch', rawResponse, body);
  };

  delete = <T = any, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig = {},
    rawResponse?: boolean,
  ) => {
    return this.configRequestMethod<R>(url, config, 'delete', rawResponse);
  };
}
