import icShield from '../dashboard/ic_shield.svg';
import icUserCircle from '../dashboard/ic_user_circle.svg';
import icUserRemove from '../dashboard/ic_user_remove.svg';
import icViewDetail from '../dashboard/ic_view_detail.svg';
import icLogout from '../dashboard/icLogout.svg';
import icArrowBottom from './arrow-bottom.svg';
import IconArrowBlue from './ArrowBlue.svg';
import CheckBoxChecked from './check-box-checked.svg';
import CheckBoxUnChecked from './check-box-uncheck.svg';
import EyeOpen from './Eye-Open.svg';
import icAccount from './ic_account.svg';
import icArrowLeft from './ic_arrow_left.svg';
import icArrowRightActive from './ic_arrow_right-active.svg';
import icCircleLogo from './ic_circle_logo.svg';
import icClock from './IC_clock.svg';
import icDuplicate from './IC_Copy.svg';
import icDragDrop from './ic_drag_drop.svg';
import icDriverLeft from './ic_driver_left.svg';
import icExpired from './ic_expired.svg';
import icLoading from './ic_loading.svg';
import icLogo from './ic_logo.svg';
import icMail from './IC_mail.svg';
import icMenuCheckedActive from './ic_menu_checked_active.svg';
import icMenuChecked from './ic_menu_checked.svg';
import icMenuCookieActive from './ic_menu_cookie_active.svg';
import icMenuCookie from './ic_menu_cookie.svg';
import icMenuNotifications from './ic_menu_notification.svg';
import icMenuProfile from './ic_menu_user_unactive.svg';
import icMenuProfileActive from './ic_menu_user.svg';
import icNotification from './ic_notification.svg';
import icPending from './ic_pending.svg';
import icPlus from './ic_plus.svg';
import icSearch from './ic_search.svg';
import icSortRight from './ic_sort_right.svg';
import icSortRightActive from './ic_sort_right_active.svg';
import icStarBlue from './ic_star_blue.svg';
import icStarGreen from './ic_star_green.svg';
import icStarYellow from './ic_star_yellow.svg';
import icTrashDisabled from './IC_trash_disabled.svg';
import icTrash from './IC_trash.svg';
import icVerified from './ic_verified.svg';
import icArrowRightLight from './ic-arrow-right-light.svg';
import icArrowRight from './ic-arrow-right.svg';
import icArrowRightBlack from './ic_arrow_right.svg';
import icArrowSelect from './ic-arrow-select.svg';
import icBack from './ic-back.svg';
import icBookmark from './ic-bookmark.svg';
import icCalendar from './ic-calendar.svg';
import icChangePage from './ic-change-page.svg';
import icCheck from './ic-check.svg';
import icCircle from './ic-circle.svg';
import icClear from './ic-clear.svg';
import icClosable from './ic-closable.svg';
import icCloseMobile from './ic-close-mobile.svg';
import icCopyActive from './ic-copy-active.svg';
import icCopy from './ic-copy.svg';
import icCreditCard from './ic-credit-card.svg';
import icDown from './ic-down.svg';
import icExport from './ic-export.svg';
import icSortBy from './ic-filter-by-time.svg';
import icFilter from './ic-filter.svg';
import IconArrow from './ic-icon-arrow-white.svg';
import icImageSkeleton from './ic-image-skeleton.svg';
import icMobileNav from './ic-mobile-nav.svg';
import IconNextArrow from './ic-next-arrow.svg';
import icNextPageV2 from './ic-next-page-v2.svg';
import icNextPage from './ic-next-page.svg';
import icOutlineCreditCard from './ic-outline-credit-card.svg';
import IconPasswordInVisible from './ic-password-invisible.svg';
import IconPasswordVisible from './ic-password-visible.svg';
import icPreviousPageV2 from './ic-previous-page-v2.svg';
import icPreviousPage from './ic-previous-page.svg';
import icSendRequest from './ic-send-request.svg';
import icSettings from './ic-settings.svg';
import icSort from './ic-sort.svg';
import icStatus from './ic-status.svg';
import icUserGroup from './ic-user-group.svg';
import icUser from './ic-user.svg';
import icUUID from './ic-uuid.svg';
import icWarning from './ic-warning.svg';
import icWebsite from './ic-website.svg';
import icCopyWhite from './IcCoppyWhite.svg';
import icCopyBlue from './ICCopyBlue.svg';
import icDeleteUser from './icDeleteUser.svg';
import icIDV from './icIDV.svg';
import icLogoGray from './icLogoGray.svg';
import ICMail from './ICMail.svg';
import ICEyeOpen from './IconEyeOpen.svg';
import icPhone from './icPhone.svg';
import IconUser from './icUser.svg';
import ImageSlideA from './ImageSlideA.svg';
import icInformation from './informationIC.svg';
import Logo from './Logo.svg';
import RegisterBG from './RegisterImg.svg';
import icWavingHand from './waving-hand.svg';
import icPencil from './ic_pencil.svg';
import icMailGray from './ic_mail_gray.svg';
import icTime from './ic_time.svg';
import icDownArrow from './ic-down-arrow.svg';
import icArrowUp from './ic_arrow_up.svg';
import icUS from './ic_us.svg';
import icUK from './ic_uk.svg';
import icCanada from './ic_canada.svg';
import icArrowSelectDial from './ic_arrow_select_dial.svg';
import icTabMain from './ic_tab_main.svg';
import icSecurity from './ic_security.svg';
import icProfile from './ic_profile.svg';
import icMessage from './ic_message.svg';
import icMobile from './ic_phone.svg';
import icContact from './ic_contact.svg';
import icBackBlack from './ic-back-black.svg';
import icSettingsPlaid from './settings.svg';
import icConfirm from './ic_confirm.svg';
import icMX from './ic_mx.svg';
import icDE from './ic_de.svg';
import icFR from './ic_fr.svg';
import icVN from './ic_vn.svg';
import icTH from './ic_th.svg';
import icES from './ic_es.svg';
import icAR from './ic_ar.svg';
import icAU from './ic_au.svg';
import icBR from './ic_br.svg';
import icCH from './ic_ch.svg';
import icCO from './ic_co.svg';
import icIL from './ic_il.svg';
import icIN from './ic_in.svg';
import icJP from './ic_jp.svg';
import icKR from './ic_kr.svg';
import icNL from './ic_nl.svg';
import icPL from './ic_pl.svg';
import icSE from './ic_se.svg';
import icTR from './ic_tr.svg';
import icZA from './ic_za.svg';
import icValid from './ic_valid.svg';
import icInValidDefault from './ic_in_valid_default.svg';
import icInValid from './ic_in_valid.svg';

export default {
  icContact,
  icMessage,
  icMobile,
  icProfile,
  icSecurity,
  icArrowRightBlack,
  icTabMain,
  icUS,
  icUK,
  icCanada,
  icArrowSelectDial,
  icArrowUp,
  icDownArrow,
  icTime,
  icMailGray,
  icPencil,
  icSortRightActive,
  icSortRight,
  icStarYellow,
  icStarBlue,
  icStarGreen,
  icDragDrop,
  icLogo,
  icCircleLogo,
  icDuplicate,
  icCopyBlue,
  icCopyWhite,
  icTrash,
  icTrashDisabled,
  icMail,
  icClock,
  icLogoGray,
  icArrowRight,
  icArrowLeft,
  icPlus,
  icIDV,
  icWarning,
  icMenuProfile,
  icMenuCheckedActive,
  icMenuProfileActive,
  icMenuChecked,
  icMenuCookie,
  icMenuCookieActive,
  icArrowRightActive,
  icDriverLeft,
  icVerified,
  icExpired,
  icPending,
  icLoading,
  icMenuNotifications,
  icAccount,
  icSearch,
  icCalendar,
  icNotification,
  icWavingHand,
  icArrowBottom,
  icExport,
  icDown,
  icSendRequest,
  icUser,
  icUUID,
  icWebsite,
  icStatus,
  icPreviousPage,
  icPreviousPageV2,
  icNextPage,
  icNextPageV2,
  icChangePage,
  icFilter,
  icViewDetail,
  icUserGroup,
  icArrowSelect,
  IconArrow,
  IconPasswordVisible,
  IconPasswordInVisible,
  IconUser,
  IconNextArrow,
  RegisterBG,
  icCheck,
  ImageSlideA,
  Logo,
  icClosable,
  IconArrowBlue,
  icSettings,
  icCopy,
  icCopyActive,
  icArrowRightLight,
  icBack,
  icSort,
  icCreditCard,
  icClear,
  icCircle,
  icInformation,
  icBookmark,
  icShield,
  icUserCircle,
  icUserRemove,
  ICMail,
  ICEyeOpen,
  icMobileNav,
  icSortBy,
  icLogout,
  CheckBoxUnChecked,
  CheckBoxChecked,
  EyeOpen,
  icCloseMobile,
  icPhone,
  icDeleteUser,
  icOutlineCreditCard,
  icImageSkeleton,
  icBackBlack,
  icSettingsPlaid,
  icConfirm,
  icAR,
  icAU,
  icBR,
  icCH,
  icCO,
  icIL,
  icIN,
  icJP,
  icKR,
  icNL,
  icPL,
  icSE,
  icTR,
  icZA,
  icMX,
  icDE,
  icFR,
  icVN,
  icTH,
  icES,
  icValid,
  icInValidDefault,
  icInValid,
};
