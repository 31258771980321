import { IListMessageResponse } from 'apis/message.api';
import { createReducer } from 'typesafe-actions';
import { getListMessage } from './message.action';

export interface MessageStoreModel {
  loading: boolean;
  listMessage: IListMessageResponse;
}
const INITIAL_STATE: MessageStoreModel = {
  loading: false,
  listMessage: null,
};
const messageReducer = createReducer<MessageStoreModel>(INITIAL_STATE)
  .handleAction(getListMessage.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListMessage.success, (state, { payload }) => ({
    ...state,
    listMessage: payload,
    loading: false,
  }))
  .handleAction(getListMessage.failure, (state) => ({
    ...state,
    loading: false,
  }));
export default messageReducer;
