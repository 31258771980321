import { VerificationsByPartnerStoreModel } from 'models/store/verifications-by-partner.model';
import { createReducer } from 'typesafe-actions';
import {
  getDetailCompany,
  getDetailVerifications,
  getListVerifications,
  getStatisticCharts,
  handleActiveCompanyAction,
  handleDeactiveCompanyAction,
  handleViewCompanyAction,
} from './verifications.action';

const INITIAL_STATE: VerificationsByPartnerStoreModel = {
  loading: false,
  loadingCharts: false,
  listVerifications: null,
  verificationDetail: null,
  companyDetail: null,
  token: null,
  dataCharts: null,
};

const verificationByPartnerReducer = createReducer<
  VerificationsByPartnerStoreModel
>(INITIAL_STATE)
  .handleAction(getListVerifications.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListVerifications.success, (state, { payload }) => ({
    ...state,
    listVerifications: payload,
    loading: false,
  }))
  .handleAction(getListVerifications.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getStatisticCharts.request, (state) => ({
    ...state,
    loadingCharts: true,
  }))
  .handleAction(getStatisticCharts.success, (state, { payload }) => ({
    ...state,
    dataCharts: payload,
    loadingCharts: false,
  }))
  .handleAction(getStatisticCharts.failure, (state) => ({
    ...state,
    loadingCharts: false,
  }))
  .handleAction(getDetailVerifications.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getDetailVerifications.success, (state, { payload }) => ({
    ...state,
    verificationDetail: payload,
    loading: false,
  }))
  .handleAction(getDetailVerifications.failure, (state) => ({
    ...state,
    verificationDetail: null,
    loading: false,
  }))
  .handleAction(handleDeactiveCompanyAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(handleDeactiveCompanyAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
  .handleAction(handleDeactiveCompanyAction.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(handleActiveCompanyAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(handleActiveCompanyAction.success, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
  .handleAction(handleActiveCompanyAction.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getDetailCompany.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getDetailCompany.success, (state, { payload }) => ({
    ...state,
    companyDetail: payload,
    loading: false,
  }))
  .handleAction(getDetailCompany.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(handleViewCompanyAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(handleViewCompanyAction.success, (state, { payload }) => ({
    ...state,
    token: payload,
    loading: false,
  }))
  .handleAction(handleViewCompanyAction.failure, (state) => ({
    ...state,
    loading: false,
  }));

export default verificationByPartnerReducer;
