import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import CookieBrowser from 'js-cookie';
import CONFIG from 'config';
import tokenManger from 'helpers/token-manager.helper';
import { getMessageFromError } from 'helpers/common.helper';
import { createBrowserHistory } from 'history';
import ROUTES from 'constants/routes.constant';
import { toastError } from 'helpers/notification.helper';
import { acceptInvitationUserApi } from 'apis/users.api';
import { AxiosError } from 'axios';
import {
  loginApi,
  LoginApiResponse,
  logoutApi,
  login2FAApi,
} from 'apis/auth.api';
import {
  loginAction,
  login2FAAction,
  logoutAction,
  doLogoutAction,
  loginAcceptInvitationUserAction,
} from './auth.action';

const history = createBrowserHistory();
function* handleLogin({ payload }: ReturnType<typeof loginAction.request>) {
  try {
    const response: LoginApiResponse = yield call(loginApi, payload);
    if (response) {
      if (!response?.data?.enableTwoFactorAuthentication) {
        tokenManger.setToken(response.data.token);
        yield put(
          loginAction.success({
            token: response.data.token,
          }),
        );
      }
      if (response?.data?.enableTwoFactorAuthentication) {
        yield put(
          loginAction.failure({
            enable2FA: response?.data?.enableTwoFactorAuthentication,
            sessionId: response?.data?.sessionId,
            phone: response?.data?.last3PhoneNumberDigits,
            error: '',
          }),
        );
        if (response?.data?.twoFactorAuthenticationType === 'sms') {
          yield put(push(`${ROUTES.AUTH.VERIFY_2FA}?id=sms`));
        } else {
          yield put(push(ROUTES.AUTH.VERIFY_2FA));
        }
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'Login Failed';
    }
  } catch (error) {
    toastError(error);
    yield put(loginAction.failure({ error: getMessageFromError(error) }));
  }
}
function* handleLogin2FA({
  payload,
}: ReturnType<typeof login2FAAction.request>) {
  try {
    const response: LoginApiResponse = yield call(login2FAApi, payload);
    if (response) {
      tokenManger.setToken(response.data.token);
      yield put(
        login2FAAction.success({
          token: response.data.token,
        }),
      );
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'Login Failed';
    }
  } catch (error) {
    toastError(error);
    yield put(login2FAAction.failure(getMessageFromError(error)));
  }
}
function* handleLoginAcceptInvitationUser({
  payload,
}: ReturnType<typeof loginAcceptInvitationUserAction.request>) {
  try {
    const response: LoginApiResponse = yield call(
      acceptInvitationUserApi,
      payload?.idUser,
      payload?.data,
    );
    if (response) {
      tokenManger.setToken(response.data.token);
      yield put(
        loginAcceptInvitationUserAction.success({
          token: response.data.token,
        }),
      );
      if (CONFIG.NAME === 'dev') {
        yield put(push(ROUTES.MAIN.PROFILE));
      }
      if (CONFIG.NAME !== 'dev') {
        yield put(push(ROUTES.MAIN.HOME));
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'Login Failed';
    }
  } catch (err) {
    const error = err as AxiosError | any;
    yield put(
      loginAcceptInvitationUserAction.failure(getMessageFromError(error)),
    );
    if (
      error?.response?.data?.message ===
      'Your invitation link has expired. Please contact admin@dentity.com to receive a new invitation link.'
    ) {
      yield put(push(ROUTES.PUBLIC.INVITATION_EXPIRED));
    } else {
      toastError(error?.response?.data?.message);
    }
  }
}

function* handleLogout() {
  try {
    CookieBrowser.remove('session_id');
    yield call(logoutApi);
    yield put(logoutAction.success());
  } catch (error) {
    yield put(logoutAction.failure());
  }
  yield call(tokenManger.doLogout);
}

function* handleDoLogout() {
  yield call(history.replace, ROUTES.AUTH.LOGIN);
}

export default function* authSaga() {
  yield takeLatest(loginAction.request, handleLogin);
  yield takeLatest(login2FAAction.request, handleLogin2FA);
  yield takeLatest(
    loginAcceptInvitationUserAction.request,
    handleLoginAcceptInvitationUser,
  );
  yield takeLatest(logoutAction.request, handleLogout);
  yield takeLatest(doLogoutAction, handleDoLogout);
}
