import { put, takeLatest, call } from '@redux-saga/core/effects';

import { getDetailVerifyApi, getListVerifyApi } from 'apis/verify.api';
import { ListVerifyResponse } from 'models/store/verify.model';
import { getDetailVerify, getListVerify } from './verify.action';

function* handleGetListVerify({
  payload,
}: ReturnType<typeof getListVerify.request>) {
  try {
    const response: ListVerifyResponse = yield call(getListVerifyApi, payload);
    yield put(getListVerify.success(response));
  } catch (e) {
    yield put(getListVerify.failure(e));
  }
}

function* handleGetDetailVerify({
  payload,
}: ReturnType<typeof getDetailVerify.request>) {
  try {
    const response: Await<ReturnType<typeof getDetailVerifyApi>> = yield call(
      getDetailVerifyApi,
      payload,
    );
    const { data } = response;
    yield put(getDetailVerify.success(data));
  } catch (e) {
    yield put(getDetailVerify.failure(e));
  }
}

export default function* verifySaga() {
  yield takeLatest(getListVerify.request, handleGetListVerify);
  yield takeLatest(getDetailVerify.request, handleGetDetailVerify);
}
