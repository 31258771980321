import { createAction, createAsyncAction } from 'typesafe-actions';

type InitialSessionPayload = {
  token: string;
};

export const initSessionAction = createAction(`auth/INIT_SESSION`)<
  InitialSessionPayload
>();

type LoginPayload = {
  email: string;
  password: string;
};
type Login2FAPayload = {
  sessionId: string;
  otpCode: string;
};
type LoginAcceptInvitationUserPayload = {
  idUser: string;
  data: {
    sessionId: string;
    password: string;
  };
};
type LoginSucceededPayload = {
  token: string;
};
type Login2FASucceededPayload = {
  token: string;
};
type LoginFailedPayload = {
  enableTwoFactorAuthentication?: boolean;
  twoFactorAuthenticationType?: string;
  sessionId?: string;
  phone: string;
  error: string;
};
export const loginAction = createAsyncAction(
  `auth/LOGIN_REQUEST`,
  `auth/LOGIN_SUCCEEDED`,
  `auth/LOGIN_FAILED`,
)<LoginPayload, LoginSucceededPayload, Partial<LoginFailedPayload>>();

export const login2FAAction = createAsyncAction(
  `auth/LOGIN_2FA_REQUEST`,
  `auth/LOGIN_2FA_SUCCEEDED`,
  `auth/LOGIN_2FA_FAILED`,
)<Login2FAPayload, Login2FASucceededPayload, string>();

export const loginAcceptInvitationUserAction = createAsyncAction(
  `auth/LOGIN_ACCEPT_INVITATION_USER_REQUEST`,
  `auth/LOGIN_ACCEPT_INVITATION_USER_SUCCEEDED`,
  `auth/LOGIN_ACCEPT_INVITATION_USER_FAILED`,
)<LoginAcceptInvitationUserPayload, Login2FASucceededPayload, string>();

export const logoutAction = createAsyncAction(
  `auth/LOGOUT_REQUEST`,
  `auth/LOGOUT_SUCCEEDED`,
  `auth/LOGOUT_FAILED`,
)<void, void, void>();

export const doLogoutAction = createAction(`auth/DO_LOGOUT`)<void>();
