import { put, takeLatest, call } from '@redux-saga/core/effects';

import { getListNetworkApi, getListVerifyApi } from 'apis/network.api';
import { getListNetwork, getListVerifyRequest } from './network.action';

function* handleGetListNetwork({
  payload,
}: ReturnType<typeof getListNetwork.request>) {
  try {
    const response: Await<ReturnType<typeof getListNetworkApi>> = yield call(
      getListNetworkApi,
      payload,
    );
    const { data } = response;
    yield put(getListNetwork.success(data));
  } catch (e) {
    yield put(getListNetwork.failure(e));
  }
}

function* handleGetListVerifyRequest({
  payload,
}: ReturnType<typeof getListVerifyRequest.request>) {
  try {
    const response: Await<ReturnType<typeof getListVerifyApi>> = yield call(
      getListVerifyApi,
      payload,
    );
    const { data } = response;
    yield put(getListVerifyRequest.success(data));
  } catch (e) {
    yield put(getListVerifyRequest.failure(e));
  }
}

export default function* networkSaga() {
  yield takeLatest(getListNetwork.request, handleGetListNetwork);
  yield takeLatest(getListVerifyRequest.request, handleGetListVerifyRequest);
}
