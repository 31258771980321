import {
  GetStatisticResponsive,
  ListWebsite,
} from 'models/store/companies.model';
import authorizedRequest from 'apis/request/authorizedRequest';
import queryString from 'query-string';
import { PREFIX_API } from 'apis/request/config.endpoint';
import downloadFileRequest from 'apis/request/downloadFileRequest';
import omit from 'lodash/omit';
import {
  ActiveCompanyBody,
  CommonApiParam,
  CompanyDetail,
  DeactiveCompanyBody,
  GetWebsiteList,
  ListVerificationsResponse,
  VerificationsDetail,
} from '../models/store/verifications-by-partner.model';

export const getListVerificationsByPartnerApi = (
  dataParams: CommonApiParam,
) => {
  return authorizedRequest.get<ListVerificationsResponse>(
    `${PREFIX_API}/verification`,
    {
      params: dataParams,
    },
  );
};
export const getDetailCompanyApi = (data: string) =>
  authorizedRequest.get<CompanyDetail>(`${PREFIX_API}/partner/${data}`);

export const getDetailVerificationdByPartnerApi = (data: string) =>
  authorizedRequest.get<VerificationsDetail>(
    `${PREFIX_API}/verification/${data}`,
  );

export const getStatisticApi = (dataDate: CommonApiParam) => {
  return authorizedRequest.get<GetStatisticResponsive>(
    `${PREFIX_API}/verification/statistic`,
    {
      params: dataDate,
    },
  );
};

export const updateProfilePartnerApi = (payload: {
  idvPrice?: string;
  issueCredentialPrice: string;
  id: string;
}) =>
  authorizedRequest.patch<void>(
    `${PREFIX_API}/partner/${payload.id}`,
    omit(payload, 'id'),
  );

export const deleteProfilePartnerApi = (id: string) =>
  authorizedRequest.delete<void>(`${PREFIX_API}/partner/${id}`);

export function deactiveCompanyApi(body: DeactiveCompanyBody) {
  return authorizedRequest.patch(`${PREFIX_API}/partner/deactivate`, body);
}
export function activeCompanyApi(body: ActiveCompanyBody) {
  return authorizedRequest.patch(`${PREFIX_API}/partner/active`, body);
}
export function viewCompanyApi(params: CommonApiParam) {
  return authorizedRequest.post(`${PREFIX_API}/partner/view`, params);
}

export const getListWebsiteApi = (dataParams: GetWebsiteList) =>
  authorizedRequest.get<ListWebsite>(`${PREFIX_API}/website`, {
    params: dataParams,
  });
export const exportVerificationsApi = (dataParams: CommonApiParam) => {
  const params = queryString.stringify(dataParams);
  return downloadFileRequest.post<string>(
    `${PREFIX_API}/verification/export?${params}`,
  );
};
