import authorizedRequest from 'helpers/request/authorized.request';
import { PREFIX_API } from './request/config.endpoint';

export interface MessageModel {
  messageType: string;
  name: string;
  status: string;
  templateId: { name: string };
  listSize: number;
  _id: string;
  partner?: {
    businessName: string;
  };
}
export interface IListMessageParams {
  sort?: string;
  pageSize?: number;
  page?: number;
  status?: string;
}
export interface IListMessageResponse {
  data: MessageModel[];
  page?: number;
  pageSize?: number;
  totalItem?: number;
  totalPage?: number;
}
interface objectMessage {
  field: string;
  message: Array<string>;
}
export interface MessageListFailResponse {
  message: Array<objectMessage>;
}
export const getListMessageApi = (params: IListMessageParams) => {
  return authorizedRequest.get<IListMessageResponse>(
    `${PREFIX_API}/custom-message`,
    {
      params,
    },
  );
};
export const deleteMessageById = (id: string) => {
  return authorizedRequest.delete<void>(`${PREFIX_API}/custom-message/${id}`);
};
