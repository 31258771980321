import { ListInternalUserResponse, UserProfile } from 'apis/users.api';
import { createReducer } from 'typesafe-actions';

import {
  getListInternalUserAction,
  getProfileUserAction,
} from './users.action';

const defaultProfile = {
  _id: '',
  email: '',
  phoneNumber: {
    phone: '',
    dialCode: '',
  },
  firstName: '',
  lastName: '',
  enableTwoFactorAuthentication: false,
  lastChangePassword: '',
};
export interface InternalUserStoreModel {
  loading: boolean;
  listInternalUser: ListInternalUserResponse;
  profile: UserProfile;
}
const INITIAL_STATE: InternalUserStoreModel = {
  loading: false,
  listInternalUser: null,
  profile: defaultProfile,
};
const internalUserReducer = createReducer<InternalUserStoreModel>(INITIAL_STATE)
  .handleAction(getListInternalUserAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListInternalUserAction.success, (state, { payload }) => ({
    ...state,
    listInternalUser: payload,
    loading: false,
  }))
  .handleAction(getListInternalUserAction.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getProfileUserAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getProfileUserAction.success, (state, { payload }) => {
    return {
      ...state,
      profile: { ...state.profile, ...payload },
      loading: false,
    };
  })
  .handleAction(getProfileUserAction.failure, (state) => ({
    ...state,
    loading: false,
  }));
export default internalUserReducer;
