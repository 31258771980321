import { call, put, takeLatest } from 'redux-saga/effects';

import { getMessageFromError } from 'helpers/common.helper';
import { toastError } from 'helpers/toast.helper';
import {
  AcceptVerificationApi,
  appceptVerificationResponse,
  DeclinedVerificationApi,
  declinedVerificationResponse,
  detailVerificationResponse,
  getDetailVerificationApi,
  getListVerificationApi,
  listVerificationResponse,
} from 'apis/verification.api';
import {
  acceptVerificationUserAction,
  declinedlVerificationAction,
  getDetailVerificationAction,
  getListVerificationAction,
} from './verification.action';

function* handleGetListVerification({
  payload,
}: ReturnType<typeof getListVerificationAction.request>) {
  try {
    const response: listVerificationResponse = yield call(
      getListVerificationApi,
      payload,
    );
    const result = response.data.data.map((item) => {
      return {
        user: {
          phoneNumber: {
            phone: item?.user?.phoneNumber?.phone,
            dialCode: item?.user?.phoneNumber?.phone,
          },
          id: item?.user?.id,
        },
        streetNumber: item?.streetNumber,
        street: item?.street,
        city: item?.city,
        state: item?.state,
        country: item?.country,
        zip: item?.zip,
        _id: item._id,
        dateCreated: item.dateCreated,
        dateUpdated: item.dateUpdated,
        status: item.status,
        firstName: item.firstName,
        lastName: item.lastName,
        verifyStatus: item.verifyStatus,
        dateOfBirth: item.dateOfBirth,
      };
    });
    yield put(
      getListVerificationAction.success({
        data: result,
        totalItem: response.data.totalItem,
        page: response.data.page,
        totalPage: response.data.totalPage,
        pageSize: response.data.pageSize,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(getListVerificationAction.failure(getMessageFromError(error)));
  }
}

function* handleGetDetailVerification({
  payload,
}: ReturnType<typeof getDetailVerificationAction.request>) {
  try {
    const response: detailVerificationResponse = yield call(
      getDetailVerificationApi,
      payload,
    );
    yield put(
      getDetailVerificationAction.success({
        data: response.data,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(getDetailVerificationAction.failure(getMessageFromError(error)));
  }
}

function* handleAcceptVerification({
  payload,
}: ReturnType<typeof acceptVerificationUserAction.request>) {
  try {
    const response: appceptVerificationResponse = yield call(
      AcceptVerificationApi,
      payload,
    );
    yield put(
      acceptVerificationUserAction.success({
        data: response.data,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(acceptVerificationUserAction.failure(getMessageFromError(error)));
  }
}

function* handleDeclinedVerification({
  payload,
}: ReturnType<typeof declinedlVerificationAction.request>) {
  try {
    const response: declinedVerificationResponse = yield call(
      DeclinedVerificationApi,
      payload,
    );
    yield put(
      declinedlVerificationAction.success({
        data: response.data,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(declinedlVerificationAction.failure(getMessageFromError(error)));
  }
}

export default function* verificationSaga() {
  yield takeLatest(
    getListVerificationAction.request,
    handleGetListVerification,
  );
  yield takeLatest(
    getDetailVerificationAction.request,
    handleGetDetailVerification,
  );
  yield takeLatest(
    acceptVerificationUserAction.request,
    handleAcceptVerification,
  );
  yield takeLatest(
    declinedlVerificationAction.request,
    handleDeclinedVerification,
  );
}
