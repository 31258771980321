export default {
  BASE_URL: 'https://api.dentity.com/',
  CDN: 'https://hll-main-bucket.s3.amazonaws.com/',
  DENTITY_COM: 'https://app.dentity.com',
  DENTITY_COM_V2: 'https://app.dentity.com',
  NAME: 'production',
  DENTITY: 'dentity.com',
  PARTNER_URL: 'https://business.dentity.com',
  VERIFY_POPUP_DYNAMIC: (id: string) => id,
  BASE_URL_OIDC: 'https://oidc.dentity.com/',
  VERIFY_LINK_OIDC: 'https://oidc.dentity.com/oidc/',
  MARKETPLACE_URL: 'https://dentity.com/',
  ISSUE_LINK: 'https://admin.dentity.com/public/issue-credential/',
  CALLBACK_OUT:
    'https://api.dentity.com/core/api/v1/credential/orca-webhook-out',
  DID_ION: 'did:ion:EiB-Dh7bQQ2C4CfzzBBoTVT2N1nAVKh2CdYvW9Jln3r37Q',
};
