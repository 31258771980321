import { CompaniesStoreModel } from 'models/store/companies.model';
import { createReducer } from 'typesafe-actions';
import {
  getDetailCompany,
  getListCompanies,
  getStatisticCharts,
  updateParams,
  resetReducer,
} from './companies.action';

const INITIAL_STATE: CompaniesStoreModel = {
  loading: false,
  listCompanies: null,
  companyDetail: null,
  dataCharts: null,
  params: null,
};

const companiesReducer = createReducer<CompaniesStoreModel>(INITIAL_STATE)
  .handleAction(getListCompanies.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListCompanies.success, (state, { payload }) => ({
    ...state,
    listCompanies: payload,
    loading: false,
  }))
  .handleAction(getListCompanies.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getStatisticCharts.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getStatisticCharts.success, (state, { payload }) => ({
    ...state,
    dataCharts: payload,
    loading: false,
  }))
  .handleAction(getStatisticCharts.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getDetailCompany.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getDetailCompany.success, (state, { payload }) => ({
    ...state,
    companyDetail: payload,
    loading: false,
  }))
  .handleAction(getDetailCompany.failure, (state) => ({
    ...state,
    loading: false,
  }))

  .handleAction(updateParams, (state, { payload }) => ({
    ...state,
    params: payload,
  }))

  .handleAction(resetReducer, (state) => ({
    ...INITIAL_STATE,
  }));

export default companiesReducer;
