import queryString from 'query-string';
import authorizedRequest from 'helpers/request/authorized.request';
import unauthorizedRequest from 'helpers/request/unauthorized.request';
import { PREFIX_API, PREFIX_API_REPORT } from './request/config.endpoint';

export interface CreateUser {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: {
    phone: string;
    dialCode: string;
  };
  roles: string[];
  status?: string;
}
export interface UserProfile {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: {
    phone: string;
    dialCode: string;
  };
  enableTwoFactorAuthentication?: boolean;
  twoFactorAuthenticationType?: string;
  lastChangePassword?: string;
  lastEnable2FA?: string;
}
export interface ChangePassword {
  oldPassword: string;
  password: string;
}
export interface ResetPassword {
  sessionId: string;
  password: string;
}
export interface ListInternalUserParams {
  page?: number;
  pageSize?: number;
  sort?: string;
}
export interface ListInternalRoleParams {
  page?: number;
  pageSize?: number;
  sort?: string;
  name?: string;
  status?: string;
}
export interface IRole {
  id: string;
  name: string;
  description: string;
}
export interface RoleByUser {
  createdAt: string;
  id: string;
  roles: IRole[];
  status: string;
  updatedAt: string;
  user: string;
}
export interface InternalUserModel {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  roles: IRole[];
  lastLogin: string;
  status: string;
  dateCreated: string;
  dateUpdated: string;
  password: string;
  phoneNumber: {
    phone: string;
    dialCode: string;
  };
  __v: number;
}
export interface InternalRoleModel {
  createdAt: string;
  description: string;
  id: string;
  name: string;
  status: string;
  updatedAt: string;
}
export interface ListInternalUserResponse {
  data: InternalUserModel[];
  page?: number;
  pageSize?: number;
  totalItem?: number;
  totalPage?: number;
}
export interface GetProfileResponse {
  data: UserProfile;
}
export interface SendPassword {
  password: string;
  twoFactorAuthenticationType?: string;
  type?: string;
}
export interface SendPasswordResponse {
  authenticationSecret: string;
  twoFactorAuthenticationType: string;
}
export interface ListInternalRoleResponse {
  data: InternalRoleModel[];
  page?: number;
  pageSize?: number;
  totalItem?: number;
  totalPage?: number;
  count?: number;
  currentPage?: number;
}

export interface reSend2FA {
  sessionId: string;
}

export const getListInternalUserApi = (params: ListInternalUserParams) => {
  return authorizedRequest.get<ListInternalUserResponse>(
    `${PREFIX_API}/internal-user`,
    {
      params,
    },
  );
};

export const getDetailInternalUserApi = (id: string) => {
  return authorizedRequest.get<any>(`${PREFIX_API}/internal-user/${id}`);
};
export const getProfileInternalUserApi = () => {
  return authorizedRequest.get<GetProfileResponse>(`${PREFIX_API}/profile`);
};
export const createInternalUserApi = (body: CreateUser) => {
  return authorizedRequest.post<CreateUser>(
    `${PREFIX_API}/internal-user`,
    body,
  );
};
export const sendLinkChangePasswordApi = (body: { email: string }) => {
  return unauthorizedRequest.post<any>(
    `${PREFIX_API}/reset-password/send-link`,
    body,
  );
};
export const resetInternalUserPasswordApi = (body: ResetPassword) => {
  return unauthorizedRequest.put<void>(`${PREFIX_API}/reset-password`, body);
};
export const acceptInvitationUserApi = (id: string, body: ResetPassword) => {
  return unauthorizedRequest.patch<any>(
    `${PREFIX_API}/internal-user/${id}/accept-invitation`,
    body,
  );
};
export const resendInvitationUserApi = (id: string) => {
  return authorizedRequest.post<any>(
    `${PREFIX_API}/internal-user/${id}/resend-invitation-link`,
  );
};
export const updateInternalUserApi = (
  id: string,
  body: Partial<CreateUser>,
) => {
  return authorizedRequest.patch<CreateUser>(
    `${PREFIX_API}/internal-user/${id}`,
    body,
  );
};
export const updateProfileUserApi = (body: Partial<UserProfile>) => {
  return authorizedRequest.patch<Partial<UserProfile>>(
    `${PREFIX_API}/profile`,
    body,
  );
};
export const updateInternalUserStatusApi = (
  id: string,
  body: Partial<CreateUser>,
) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/internal-user/${id}/status`,
    body,
  );
};
export const changeInternalUserPasswordApi = (body: ChangePassword) => {
  return authorizedRequest.put<void>(`${PREFIX_API}/change-password`, body);
};
export const deleteInternalUserById = (id: string) => {
  return authorizedRequest.delete<void>(`${PREFIX_API}/internal-user/${id}`);
};
export const register2FALogin = (body: SendPassword) => {
  return authorizedRequest.post<SendPasswordResponse>(
    `${PREFIX_API}/two-factor-authentication/generate-registration`,
    body,
  );
};
export const verifyRegister2FALogin = (body: { otpCode: string }) => {
  return authorizedRequest.post<void>(
    `${PREFIX_API}/two-factor-authentication/verify-registration`,
    body,
  );
};
export const remove2FALogin = (body: SendPassword) => {
  return authorizedRequest.post<SendPasswordResponse>(
    `${PREFIX_API}/two-factor-authentication/remove-registration`,
    body,
  );
};
export interface CommonApiParam {
  startTime?: string | number;
  endTime?: string | number;
  name?: string;
  email?: string;
  role?: string;
  page?: number;
  sort?: string;
  status?: string;
  pageSize?: number;
}
export const exportInternalUsersApi = (params: CommonApiParam) => {
  const paramsString = queryString.stringify(params);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/internal-user/export-report-link?${paramsString}`,
  );
};

export const resend2FAOTP = (body: reSend2FA) => {
  return authorizedRequest.post<any>(
    `${PREFIX_API}/two-factor-authentication/resend`,
    body,
  );
};
