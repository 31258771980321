import { NotificationStoreState } from 'models/store/notification.model';
import { createReducer } from 'typesafe-actions';
import {
  getListNotificationAction,
  readNotificationAction,
} from './notification.action';

const initState: NotificationStoreState = {
  data: undefined,
  dataDetail: undefined,
  totalPage: 0,
  totalItem: 0,
  pageSize: 0,
  page: 0,
  totalUnread: 0,
  loading: false,
};

const notificationReducer = createReducer<NotificationStoreState>(initState)
  .handleAction(getListNotificationAction.request, (state) => {
    return { ...state, data: undefined, loading: true };
  })
  .handleAction(getListNotificationAction.success, (state, { payload }) => {
    return {
      ...state,
      data: payload.data,
      totalItem: payload.totalItem,
      totalUnread: payload.totalUnread,
      loading: false,
    };
  })
  .handleAction(getListNotificationAction.failure, (state, { payload }) => {
    return { ...state, loading: false, error: payload };
  })
  .handleAction(readNotificationAction.request, (state) => {
    return { ...state, data: undefined, loading: true };
  })
  .handleAction(readNotificationAction.success, (state, { payload }) => {
    return { ...state, dataDetail: payload.data, loading: false };
  })
  .handleAction(readNotificationAction.failure, (state, { payload }) => {
    return { ...state, loading: false, error: payload };
  });

export default notificationReducer;
