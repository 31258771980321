import authorizedRequest from 'apis/request/authorizedRequest';
import { PREFIX_API, PREFIX_API_REPORT } from 'apis/request/config.endpoint';
import {
  IDetailVerifyRes,
  INetworkData,
  ListNetworkResponse,
  ListVerifyRequestResponse,
} from 'models/store/network.model';
import {
  IExportNetworkParams,
  IListNetworkParams,
} from 'store/network/network.action';

import queryString from 'query-string';
import downloadFileRequest from 'apis/request/downloadFileRequest';
import { IMessageData } from 'models/store/message.model';

export interface CreateMessageBlastBody {
  name: string;
  templateId: string;
  schedule?: number;
  selectReceivers?: string[];
  messageType: string;
  message: string;
  status: string;
}

export interface CreateMessageAutomationBody {
  name: string;
  templateId: string;
  triggers?: string[];
  delay?: DelayTime;
  numberOfMessages?: number;
  messageType?: string;
  message?: string;
  status?: string;
  timePeriod?: TimePeriod;
}

export interface DelayTime {
  delayTime: number;
  unit: string;
}

export interface TimePeriod {
  beginTime: number;
  endTime: number;
}

export const getDetailNetworkApi = (id: string) => {
  return authorizedRequest.get<INetworkData, INetworkData>(
    `${PREFIX_API}/credential/history/${id}`,
  );
};

export const getListNetworkApi = (params: IListNetworkParams) => {
  return authorizedRequest.get<ListNetworkResponse>(
    `${PREFIX_API}/credential/history`,
    { params },
  );
};

export const getListVerifyApi = (params: IListNetworkParams) => {
  return authorizedRequest.get<ListVerifyRequestResponse>(
    `${PREFIX_API}/verify-request`,
    { params },
  );
};

export const getDetailVerifyNetworkApi = (id: string) => {
  return authorizedRequest.get<IDetailVerifyRes, IDetailVerifyRes>(
    `${PREFIX_API}/verify-request/${id}`,
  );
};

export const exportNetworksApi = (
  dataParams: Partial<IExportNetworkParams>,
) => {
  const params = queryString.stringify(dataParams);
  return downloadFileRequest.post<string>(
    `${PREFIX_API}/credential/history/export?${params}`,
  );
};

export const getLinkDownLoad = (dataParams: Partial<IExportNetworkParams>) => {
  const params = queryString.stringify(dataParams);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/credential/history/export-report-link?${params}`,
  );
};

export const getLinkDownLoadVerify = (
  dataParams: Partial<IExportNetworkParams>,
) => {
  const params = queryString.stringify(dataParams);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/verify-request/export-report-link?${params}`,
  );
};

export const revokeNetworkApi = (id: string) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/credential/history/${id}/revoke`,
  );
};

export const createMessageApi = (body: object) => {
  return authorizedRequest.post<any>(`${PREFIX_API}/custom-message`, body);
};

export const editMessageApi = (body: object, id: string) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/custom-message/${id}`,
    body,
  );
};

export const getDetailMessageApi = (id: string) => {
  return authorizedRequest.get<IMessageData, IMessageData>(
    `${PREFIX_API}/custom-message/${id}`,
  );
};
