import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { stringify } from 'query-string';

import CONFIG from 'config';
import { LANGUAGES } from 'constants/i18n.constant';
import moment from 'moment-timezone';
import tokenManager from '../token-manager.helper';
import languageManager from '../language-manager.helper';
import { toastError } from '../notification.helper';

const authorizedOIDCRequest: AxiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL_OIDC,
  headers: {
    'Content-Type': 'application/json',
    'x-timezone': moment.tz.guess(),
  },
  paramsSerializer: (params) => {
    return stringify(params, { arrayFormat: 'bracket' });
  },
  params: {},
});

authorizedOIDCRequest.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  const { token } = tokenManager;
  newConfig.headers.common.Authorization = token ? `Bearer ${token}` : '';
  const { currentLang } = languageManager;
  newConfig.headers.common['Accept-Language'] = LANGUAGES[currentLang];
  return newConfig;
});

authorizedOIDCRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  (error: AxiosError<any>) => {
    if (error && error.response && error.response.status === 401) {
      tokenManager.doLogout();
      tokenManager.showMessageExpiredOnce && toastError('Session Expired');
      tokenManager.doLogout();
      tokenManager.setShowMessageExpiredOnce(false);
      // eslint-disable-next-line no-throw-literal
      throw 401;
    } else {
      throw error;
    }
  },
);

export default authorizedOIDCRequest;
