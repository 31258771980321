import { fork } from '@redux-saga/core/effects';
import accountSaga from './account/account.saga';
import authSaga from './auth/auth.saga';
import verificationSaga from './verification/verification.saga';
import notificationSaga from './notification/notification.saga';
import verificationsSaga from './verifications/verifications.saga';
import verificationsByPartnerSaga from './verifications-by-partner/verifications.saga';
import companiesSaga from './companies/companies.saga';
import credentialSaga from './credential/credential.saga';
import networkSaga from './network/network.saga';
import verifySaga from './verify/verify.saga';
import marketplaceSaga from './marketplace/marketplace.saga';
import messageSaga from './message/message.saga';
import permissionSaga from './permission/permission.saga';
import internalUserSaga from './users/users.saga';

export default function* rootSaga() {
  try {
    yield fork(authSaga);
    yield fork(verificationSaga);
    yield fork(accountSaga);
    yield fork(notificationSaga);
    yield fork(verificationsSaga);
    yield fork(verificationsByPartnerSaga);
    yield fork(companiesSaga);
    yield fork(credentialSaga);
    yield fork(networkSaga);
    yield fork(verifySaga);
    yield fork(marketplaceSaga);
    yield fork(messageSaga);
    yield fork(permissionSaga);
    yield fork(internalUserSaga);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
