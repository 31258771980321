import {
  CommonApiParam,
  ListCompaniesResponse,
  CompanyDetail,
  GetStatisticResponsive,
} from 'models/store/companies.model';
import { createAsyncAction, createAction } from 'typesafe-actions';

interface objectMessage {
  field: string;
  message: Array<string>;
}

interface LoginFailResponse {
  message: Array<objectMessage>;
}
export const getListCompanies = createAsyncAction(
  `@companies/GET_LIST_COMPANIES_REQUEST`,
  `@companies/GET_LIST_COMPANIES_SUCCESS`,
  `@companies/GET_LIST_COMPANIES_FAIL`,
)<CommonApiParam, ListCompaniesResponse, LoginFailResponse>();

export const getStatisticCharts = createAsyncAction(
  `@companies/GET_STATISTIC_REQUEST`,
  `@companies/GET_STATISTIC_SUCCESS`,
  `@companies/GET_STATISTIC_FAIL`,
)<CommonApiParam, GetStatisticResponsive, void>();

export const getDetailCompany = createAsyncAction(
  `@companies/GET_DETAIL_COMPANY_REQUEST`,
  `@companies/GET_DETAIL_COMPANY_SUCCESS`,
  `@companies/GET_DETAIL_COMPANY_FAIL`,
)<string, CompanyDetail, void>();

export const updateParams = createAction('@companies/UPDATE_PARAMS')<
  CommonApiParam
>();

export const resetReducer = createAction('@companies/RESET_REDUCER')();
