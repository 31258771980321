import React, { ErrorInfo } from 'react';

import { isDevelopment } from 'helpers/common.helper';
import classes from './error-boundary.module.scss';

interface Props {}

interface State {
  hasError: boolean;
  error?: ErrorInfo;
}

class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    // eslint-disable-next-line no-console
    console.log('Error: ', error);
    this.setState({ hasError: true, error: errorInfo });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.hasError && isDevelopment()) {
      return (
        <div className={classes.container}>
          <h3>Something went wrong!!!</h3>
          <p>{this.state.error?.componentStack}</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
