import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import classes from './nav-link-item.module.scss';

interface Props {
  label: string;
  icon: ReactElement;
  isHighLighted?: boolean;
  url: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const NavLinkItem: FC<Props> = ({
  label,
  icon,
  isHighLighted,
  url,
  disabled = false,
  className,
  onClick,
}) => {
  return (
    <>
      {disabled ? (
        <div className={classes.navItemContainer}>
          {icon}
          <div
            className={cx(classes.label, {
              [classes.selected]: isHighLighted,
            })}
          >
            {label}
          </div>
        </div>
      ) : (
        <Link to={url} className={className} onClick={onClick}>
          <div className={classes.navItemContainer}>
            {icon}
            <div
              className={cx(classes.label, {
                [classes.selected]: isHighLighted,
              })}
            >
              {label}
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default NavLinkItem;
