import { toast, ToastOptions } from 'react-toastify';
import { get } from 'lodash';
import { getMessageFromError } from './common.helper';

const optionsError = {
  type: toast.TYPE.ERROR,
  closeButton: null,
  autoClose: 2000,
  position: toast.POSITION.TOP_RIGHT,
  className: 'custom-toast',
} as ToastOptions;

const optionsSuccess = {
  type: toast.TYPE.SUCCESS,
  closeButton: null,
  autoClose: 2000,
  position: toast.POSITION.TOP_RIGHT,
  className: 'custom-toast',
} as ToastOptions;

function _toastError(error: any, duration?: number) {
  const options = duration
    ? { ...optionsError, autoClose: duration }
    : optionsError;
  return toast(getMessageFromError(error), options);
}

function _toastSuccess(success: any, duration?: number) {
  const options = duration
    ? { ...optionsSuccess, autoClose: duration }
    : optionsSuccess;
  if (typeof success === 'string') {
    return toast(success, options);
  }
  if (success.response) {
    return toast(
      get(success.response, 'data.message') || success.message,
      options,
    );
  }
  if (success.request) {
    return toast('Network error', options);
  }
  return toast(success.message, options);
}

class Toast {
  toast: any = null;

  toastSuccess = (message: any, duration: number = 3000) => {
    if (!toast.isActive(this.toast)) {
      this.toast = _toastSuccess(message, duration);
    }
  };

  toastError = (message: any, duration: number = 3000) => {
    if (!toast.isActive(this.toast)) {
      this.toast = _toastError(message, duration);
    }
  };
}

const toastSuccessInstance = new Toast();
const toastErrorInstance = new Toast();

const { toastSuccess } = toastSuccessInstance;
const { toastError } = toastErrorInstance;

export { toastSuccess, toastError };
