import {
  ListCredentialResponse,
  DetailTemplate,
  DeleteTemplateParams,
  CreateOfferParams,
  CreateOfferResponse,
  ListWalletInUserResponse,
  ListWalletResponse,
} from 'models/store/credential.model';
import { createAction, createAsyncAction } from 'typesafe-actions';

interface objectMessage {
  field: string;
  message: Array<string>;
}

interface LoginFailResponse {
  message: Array<objectMessage>;
}
export interface IListCredentialParams {
  sort?: string;
  pageSize?: number;
  page?: number;
  status?: string;
}

export interface GetListWalletParams {
  walletId?: string;
  page: number;
  sort?: string;
  _id?: string;
}

export const getListCredential = createAsyncAction(
  `@credential/GET_LIST_CREDENTIAL_REQUEST`,
  `@credential/GET_LIST_CREDENTIAL_SUCCESS`,
  `@credential/GET_LIST_CREDENTIAL_FAIL`,
)<IListCredentialParams, ListCredentialResponse, LoginFailResponse>();
export const getListWallet = createAsyncAction(
  `@wallet/GET_LIST_WALLET_REQUEST`,
  `@wallet/GET_LIST_WALLET_SUCCESS`,
  `@wallet/GET_LIST_WALLET_FAIL`,
)<GetListWalletParams, ListWalletResponse, LoginFailResponse>();

export const getDetailTemplate = createAsyncAction(
  `@credential/GET_DETAIL_TEMPLATE_REQUEST`,
  `@credential/GET_DETAIL_TEMPLATE_SUCCESS`,
  `@credential/GET_DETAIL_TEMPLATE_FAIL`,
)<string, DetailTemplate, LoginFailResponse>();
export const deleteTemplate = createAsyncAction(
  `@credential/DELETE_TEMPLATE_REQUEST`,
  `@credential/DELETE_TEMPLATE_SUCCESS`,
  `@credential/DELETE_TEMPLATE_FAIL`,
)<DeleteTemplateParams, void, LoginFailResponse>();
export const createOfferAction = createAsyncAction(
  `@credential/CREATE_OFFER_REQUEST`,
  `@credential/CREATE_OFFER_SUCCESS`,
  `@credential/CREATE_OFFER_FAIL`,
)<CreateOfferParams, CreateOfferResponse, LoginFailResponse>();

export const getListWalletInUser = createAsyncAction(
  `@wallet/GET_LIST_WALLET_IN_USER_REQUEST`,
  `@wallet/GET_LIST_WALLET_IN_USER_SUCCESS`,
  `@wallet/GET_LIST_WALLET_IN_USER_FAIL`,
)<string, ListWalletInUserResponse, void>();

export const clearListWalletAction = createAction(
  `@wallet/CLEAR_LIST_WALLET_IN_USER`,
)<void>();
export const clearListWalletIdAction = createAction(
  `@wallet/CLEAR_LIST_WALLET_ID`,
)<void>();
export const clearDetailTemplateAction = createAction(
  `@template/CLEAR_DETAIL_TEMPLATE`,
)<void>();

export const updateCredentialDetail = createAction(
  `@credential/UPDATE_CREDENTIAL_DETAIL`,
)<{ id: string; value: string[] }>();
