import { select } from 'redux-saga/effects';
import { put, takeLatest, call } from '@redux-saga/core/effects';
import {
  createOfferApi,
  deleteTemplateApi,
  getDetailTemplatedApi,
  getListCredentialApi,
  getListWalletApi,
  getListWalletInUserApi,
} from 'apis/credential.api';
import { toastSuccess, toastError } from 'helpers/notification.helper';
import { CredentialStoreModel } from 'models/store/credential.model';
import {
  getListCredential,
  getDetailTemplate,
  deleteTemplate,
  createOfferAction,
  getListWalletInUser,
  getListWallet,
} from './credential.action';
import { StoreState } from '../../models/store/index';

function* handleGetListCredential({
  payload,
}: ReturnType<typeof getListCredential.request>) {
  try {
    const response: Await<ReturnType<typeof getListCredentialApi>> = yield call(
      getListCredentialApi,
      payload,
    );
    const { data } = response;
    yield put(getListCredential.success(data));
  } catch (e) {
    yield put(getListCredential.failure(e));
  }
}

function* handleGetListTemplateByIdSaga({
  payload,
}: ReturnType<typeof getDetailTemplate.request>) {
  try {
    const response: Await<ReturnType<
      typeof getDetailTemplatedApi
    >> = yield call(getDetailTemplatedApi, payload);
    const { template } = response?.data;
    yield put(getDetailTemplate.success(template));
  } catch (e) {
    yield put(getDetailTemplate.failure(e));
  }
}
function* handleGetListWalletSaga({
  payload,
}: ReturnType<typeof getListWallet.request>) {
  try {
    const response: Await<ReturnType<typeof getListWalletApi>> = yield call(
      getListWalletApi,
      payload,
    );
    if (payload?.page === 1) {
      yield put(getListWallet.success(response?.data));
    } else {
      const getProject = (state: StoreState) => state.credential;
      const result: CredentialStoreModel = yield select(getProject);
      yield put(
        getListWallet.success({
          page: response?.data?.page,
          pageSize: response?.data?.pageSize,
          totalItem: response?.data?.totalItem,
          totalPage: response?.data?.totalPage,
          data: result?.listWallet?.data?.concat(response?.data?.data),
        }),
      );
    }
  } catch (e) {
    toastError(e?.data?.message);
    yield put(getListWallet.failure(e));
  }
}
function* handleGetListWalletInUserSaga({
  payload,
}: ReturnType<typeof getListWalletInUser.request>) {
  try {
    const response: Await<ReturnType<
      typeof getListWalletInUserApi
    >> = yield call(getListWalletInUserApi, payload);
    const { data } = response;
    yield put(getListWalletInUser.success(data));
  } catch (e) {
    yield put(getListWalletInUser.failure(e));
  }
}
function* handleDeleteTemplateSaga({
  payload,
}: ReturnType<typeof deleteTemplate.request>) {
  try {
    yield call(deleteTemplateApi, payload?.id);
    yield put(deleteTemplate.success());
    toastSuccess('Template Deleted');
    if (payload?.afterDelete) {
      payload?.afterDelete();
    }
  } catch (e) {
    toastError(e?.data?.message);
    yield put(deleteTemplate.failure(e));
  }
}
function* handleCreateOfferSaga({
  payload,
}: ReturnType<typeof createOfferAction.request>) {
  try {
    const response: Await<ReturnType<typeof createOfferApi>> = yield call(
      createOfferApi,
      payload?.body,
    );
    const { data } = response;
    yield put(createOfferAction.success(data));
    if (payload?.afterCreate) {
      payload?.afterCreate();
    }
    payload.refreshDataTemplate();
    toastSuccess('Offer Link Created');
  } catch (e) {
    payload.wrongWalletId(e);
    yield put(createOfferAction.failure(e));
  }
}

export default function* credentialSaga() {
  yield takeLatest(getListCredential.request, handleGetListCredential);
  yield takeLatest(getDetailTemplate.request, handleGetListTemplateByIdSaga);
  yield takeLatest(deleteTemplate.request, handleDeleteTemplateSaga);
  yield takeLatest(createOfferAction.request, handleCreateOfferSaga);
  yield takeLatest(getListWalletInUser.request, handleGetListWalletInUserSaga);
  yield takeLatest(getListWallet.request, handleGetListWalletSaga);
}
