import { call, put, takeLatest } from 'redux-saga/effects';

import { getMessageFromError } from 'helpers/common.helper';
import { toastError } from 'helpers/toast.helper';
import {
  getListNotificationApi,
  listNotificationResponse,
  readNotificationApi,
  readNotificationResponse,
} from 'apis/notification.api';
import {
  getListNotificationAction,
  readNotificationAction,
} from './notification.action';

function* handleGetListNotification({
  payload,
}: ReturnType<typeof getListNotificationAction.request>) {
  try {
    const response: listNotificationResponse = yield call(
      getListNotificationApi,
      payload,
    );
    yield put(
      getListNotificationAction.success({
        data: response.data.data,
        totalItem: response.data.totalItem,
        page: response.data.page,
        totalPage: response.data.totalPage,
        pageSize: response.data.pageSize,
        totalUnread: response?.data?.totalUnread,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(getListNotificationAction.failure(getMessageFromError(error)));
  }
}

function* handleReadNofitication({
  payload,
}: ReturnType<typeof readNotificationAction.request>) {
  try {
    const response: readNotificationResponse = yield call(
      readNotificationApi,
      payload,
    );
    yield put(
      readNotificationAction.success({
        data: response.data,
      }),
    );
  } catch (error) {
    toastError(error);
    yield put(readNotificationAction.failure(getMessageFromError(error)));
  }
}

export default function* notificationSaga() {
  yield takeLatest(
    getListNotificationAction.request,
    handleGetListNotification,
  );
  yield takeLatest(readNotificationAction.request, handleReadNofitication);
}
