import authorizedRequest from 'helpers/request/authorized.request';
import unauthorizedRequest from 'helpers/request/unauthorized.request';
import { GenericData } from 'models/common.model';
import { PREFIX_API } from './request/config.endpoint';

export interface Status {
  isActive: boolean;
}
export type LoginApiResponse = GenericData<{
  token: string;
  sessionId?: string;
  enableTwoFactorAuthentication?: boolean;
  twoFactorAuthenticationType?: string;
  last3PhoneNumberDigits: string;
  dateCreated: string;
  dateUpdated: string;
  email: string;
  lastLogin: string;
  password: string;
  role: string;
  salt: string;
  status: Status;
  adminName: string;
  _id: string;
}>;

export function loginApi(params: { email: string; password: string }) {
  return unauthorizedRequest.post<LoginApiResponse, LoginApiResponse>(
    `${PREFIX_API}/login`,
    params,
  );
}
export function login2FAApi(params: { sessionId: string; otpCode: string }) {
  return unauthorizedRequest.post<LoginApiResponse, LoginApiResponse>(
    `${PREFIX_API}/two-factor-authentication/verify-otp`,
    params,
  );
}

export function logoutApi() {
  return authorizedRequest.post(`${PREFIX_API}/logout`);
}
