import { call, put, takeLatest } from 'redux-saga/effects';
import { getListMessageApi } from 'apis/message.api';
import { getListMessage } from './message.action';

function* handleGetListMessage({
  payload,
}: ReturnType<typeof getListMessage.request>) {
  try {
    const response: Await<ReturnType<typeof getListMessageApi>> = yield call(
      getListMessageApi,
      payload,
    );
    const { data } = response;
    yield put(getListMessage.success(data));
  } catch (e) {
    yield put(getListMessage.failure(e));
  }
}
export default function* messageSaga() {
  yield takeLatest(getListMessage.request, handleGetListMessage);
}
