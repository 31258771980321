import { createReducer } from 'typesafe-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AuthStoreState } from 'models/store/auth.model';
import {
  initSessionAction,
  loginAction,
  login2FAAction,
  logoutAction,
  doLogoutAction,
  loginAcceptInvitationUserAction,
} from './auth.action';

const initState: AuthStoreState = {
  token: '',
  error: '',
  enable2FA: false,
  sessionId: '',
  phone: '',
};

const authReducer = createReducer<AuthStoreState>(initState)
  .handleAction(initSessionAction, (state, { payload }) => {
    return { ...state, token: payload.token };
  })
  .handleAction(loginAction.request, (state) => {
    return { ...state };
  })
  .handleAction(loginAction.success, (state, { payload }) => {
    return {
      ...state,
      token: payload.token,
      error: '',
    };
  })
  .handleAction(loginAction.failure, (state, { payload }) => {
    return {
      ...state,
      enable2FA: payload?.enable2FA,
      sessionId: payload?.sessionId,
      phone: payload?.phone,
      error: payload?.error,
    };
  })
  .handleAction(login2FAAction.request, (state) => {
    return { ...state };
  })
  .handleAction(login2FAAction.success, (state, { payload }) => {
    return {
      ...state,
      token: payload.token,
      error: '',
    };
  })
  .handleAction(login2FAAction.failure, (state, { payload }) => {
    return {
      ...state,
      error: payload,
    };
  })
  .handleAction(loginAcceptInvitationUserAction.request, (state) => {
    return { ...state };
  })
  .handleAction(
    loginAcceptInvitationUserAction.success,
    (state, { payload }) => {
      return {
        ...state,
        token: payload.token,
        error: '',
      };
    },
  )
  .handleAction(
    loginAcceptInvitationUserAction.failure,
    (state, { payload }) => {
      return {
        ...state,
        token: '',
        user: undefined,
        enable2FA: false,
        error: payload,
      };
    },
  )
  .handleAction(logoutAction.request, (state) => {
    return { ...state };
  })
  .handleAction(logoutAction.success, (state) => {
    return { ...state, token: '', user: undefined };
  })
  .handleAction(logoutAction.failure, (state) => {
    return { ...state };
  })
  .handleAction(doLogoutAction, (state) => {
    return { ...state, token: '', user: undefined, enable2FA: false };
  });

const persistConfig = {
  key: 'auth',
  whitelist: ['token', 'user'],
  storage,
};

export default persistReducer(persistConfig, authReducer);
