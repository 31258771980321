import { call, put, takeLatest } from '@redux-saga/core/effects';
import { uploadFileApi } from 'apis/verification.api';
import {
  createVerificationsApi,
  deleteUserApi,
  declineUserApi,
  acceptUserApi,
  exportVerificationsApi,
  getDetailVerificationsApi,
  getListVerificationsApi,
  getStatisticApi,
} from 'apis/verifications.api';
import { toastError, toastSuccess } from 'helpers/notification.helper';
import {
  acceptVerificationUserAction,
  createVerifications,
  declineVerificationUserAction,
  deleteVerificationUserAction,
  exportVerifications,
  getDetailVerifications,
  getListVerifications,
  getStatisticCharts,
  uploadFileActions,
} from './verifications.action';

function* handleGetListVerifications({
  payload,
}: ReturnType<typeof getListVerifications.request>) {
  try {
    const response: Await<ReturnType<
      typeof getListVerificationsApi
    >> = yield getListVerificationsApi(payload);

    yield put(getListVerifications.success(response.data));
  } catch (e) {
    yield put(getListVerifications.failure(e));
  }
}

function* handleExportVerificationsSaga({
  payload,
}: ReturnType<typeof exportVerifications.request>) {
  try {
    yield call(exportVerificationsApi, payload);
    yield put(exportVerifications.success());
  } catch (e) {
    yield put(exportVerifications.failure(e));
  }
}

function* getStatisticChartsSaga({
  payload,
}: ReturnType<typeof getStatisticCharts.request>) {
  try {
    const response: Await<ReturnType<typeof getStatisticApi>> = yield call(
      getStatisticApi,
      payload,
    );
    const { data } = response;
    yield put(getStatisticCharts.success(data));
  } catch (e) {
    yield put(getStatisticCharts.failure(e));
  }
}

function* handleCreateVerificationSaga({
  payload,
}: ReturnType<typeof createVerifications.request>) {
  try {
    const response: Await<ReturnType<
      typeof createVerificationsApi
    >> = yield call(createVerificationsApi, payload);
    const { data } = response;
    yield put(createVerifications.success(data));
    toastSuccess('Send verification link successfully');
  } catch (e) {
    yield put(createVerifications.failure(e));
    toastError(e.data.message);
  }
}
function* uploadFileSaga({
  payload,
}: ReturnType<typeof uploadFileActions.request>) {
  try {
    const response: Await<ReturnType<typeof uploadFileApi>> = yield call(
      uploadFileApi,
      payload,
    );
    const { data } = response;
    yield put(
      uploadFileActions.success({
        id: data && data[0]?.id,
        url: data && data[0]?.link,
      }),
    );
  } catch (e) {
    toastError(e);
    yield put(uploadFileActions.failure());
  }
}

function* handleGetDetailVerifications({
  payload,
}: ReturnType<typeof getDetailVerifications.request>) {
  try {
    const response: Await<ReturnType<
      typeof getDetailVerificationsApi
    >> = yield call(getDetailVerificationsApi, payload);
    const { data } = response;

    yield put(getDetailVerifications.success(data));
  } catch (e) {
    yield put(getDetailVerifications.failure(e));
  }
}

function* handleDeleteUser({
  payload,
}: ReturnType<typeof deleteVerificationUserAction.request>) {
  try {
    yield call(deleteUserApi, payload.id);
    payload?.handleSuccess && payload.handleSuccess();
    yield put(deleteVerificationUserAction.success());
    toastSuccess('Verification Deleted');
  } catch (e) {
    yield put(deleteVerificationUserAction.failure(e));
  }
}

function* handleDeclineVerification({
  payload,
}: ReturnType<typeof declineVerificationUserAction.request>) {
  try {
    yield call(declineUserApi, payload.id);
    payload?.handleSuccess && payload.handleSuccess();
    yield put(declineVerificationUserAction.success());
  } catch (e) {
    toastError(e);

    yield put(declineVerificationUserAction.failure(e));
  }
}

function* handleAcceptVerification({
  payload,
}: ReturnType<typeof acceptVerificationUserAction.request>) {
  try {
    yield call(acceptUserApi, payload.id);
    payload?.handleSuccess && payload.handleSuccess();
    yield put(acceptVerificationUserAction.success());
  } catch (e) {
    yield put(acceptVerificationUserAction.failure(e));
    toastError(e);
  }
}

export default function* authenticateSaga() {
  yield takeLatest(getListVerifications.request, handleGetListVerifications);
  yield takeLatest(
    getDetailVerifications.request,
    handleGetDetailVerifications,
  );
  yield takeLatest(uploadFileActions.request, uploadFileSaga);
  yield takeLatest(deleteVerificationUserAction.request, handleDeleteUser);
  yield takeLatest(createVerifications.request, handleCreateVerificationSaga);
  yield takeLatest(getStatisticCharts.request, getStatisticChartsSaga);
  yield takeLatest(exportVerifications.request, handleExportVerificationsSaga);
  yield takeLatest(
    declineVerificationUserAction.request,
    handleDeclineVerification,
  );
  yield takeLatest(
    acceptVerificationUserAction.request,
    handleAcceptVerification,
  );
}
