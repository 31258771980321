import { VerificationsStoreModel } from 'models/store/verifications.model';
import { createReducer } from 'typesafe-actions';
import {
  createVerifications,
  exportVerifications,
  getDetailVerifications,
  getListVerifications,
  getStatisticCharts,
  deleteVerificationUserAction,
  declineVerificationUserAction,
  acceptVerificationUserAction,
  uploadFileActions,
} from './verifications.action';

const INITIAL_STATE: VerificationsStoreModel = {
  loading: false,
  listVerifications: null,
  verificationDetail: null,
  dataCharts: null,
  avatar: undefined,
};

const verificationsReducer = createReducer<VerificationsStoreModel>(
  INITIAL_STATE,
)
  .handleAction(getListVerifications.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListVerifications.success, (state, { payload }) => ({
    ...state,
    listVerifications: payload,
    loading: false,
  }))
  .handleAction(getListVerifications.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(deleteVerificationUserAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(deleteVerificationUserAction.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(deleteVerificationUserAction.failure, (state) => ({
    ...state,
    loading: false,
  }))

  .handleAction(declineVerificationUserAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(declineVerificationUserAction.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(declineVerificationUserAction.failure, (state) => ({
    ...state,
    loading: false,
  }))

  .handleAction(acceptVerificationUserAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(acceptVerificationUserAction.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(acceptVerificationUserAction.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(uploadFileActions.request, (state) => ({
    ...state,
    disable: true,
  }))
  .handleAction(uploadFileActions.success, (state, { payload }) => ({
    ...state,
    avatar: payload,
    disable: false,
  }))
  .handleAction(uploadFileActions.failure, (state) => ({
    ...state,
    disable: false,
  }))

  .handleAction(exportVerifications.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(exportVerifications.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(exportVerifications.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getStatisticCharts.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getStatisticCharts.success, (state, { payload }) => ({
    ...state,
    dataCharts: payload,
    loading: false,
  }))
  .handleAction(getStatisticCharts.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getDetailVerifications.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getDetailVerifications.success, (state, { payload }) => ({
    ...state,
    verificationDetail: payload,
    loading: false,
  }))
  .handleAction(getDetailVerifications.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(createVerifications.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(createVerifications.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(createVerifications.failure, (state) => ({
    ...state,
    loading: false,
  }));

// // Reset password action
// .handleAction(resetPassword.request, (state, { payload }) => ({
//     ...state,
//     loading: true,
// }))
// .handleAction(resetPassword.success, (state, { payload }) => ({
//     ...state,
//     loading: false,
//     token: '',
// }))
// .handleAction(resetPassword.failure, (state, { payload }) => ({
//     ...state,
//     loading: false,
// }))

// // Get token check reset password
// .handleAction(checkTokenResetPassword.request, (state, { payload }) => ({
//     ...state,
//     loadingToken: true,
// }))
// .handleAction(checkTokenResetPassword.success, (state, { payload }) => ({
//     ...state,
//     tokenPassword: payload.requestToken,
//     loadingToken: false,
// }))
// .handleAction(checkTokenResetPassword.failure, (state, { payload }) => ({
//     ...state,
//     loadingToken: false,
// }))

// // Recover password action
// .handleAction(recoverPassword.request, (state, { payload }) => ({
//     ...state,
//     loading: true,
// }))
// .handleAction(recoverPassword.success, (state, { payload }) => ({
//     ...state,
//     loading: false,
//     message: payload.message,
// }))
// .handleAction(recoverPassword.failure, (state, { payload }) => ({
//     ...state,
//     loading: false,
//     message: '',
// }))

// // get avatar
// .handleAction(getImageAvatarActions.request, (state, { payload }) => ({
//     ...state,
// }))
// .handleAction(getImageAvatarActions.success, (state, { payload }) => ({
//     ...state,
//     imageAvatar: payload,
// }))
// .handleAction(getImageAvatarActions.failure, (state, { payload }) => ({
//     ...state,
// }))

// // Reset message state
// .handleAction(resetMessage.request, (state, { payload }) => ({
//     ...state,
//     message: '',
// }))
// .handleAction(resetMessage.success, (state, { payload }) => ({
//     ...state,
//     message: '',
// }));

export default verificationsReducer;
