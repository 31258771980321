import React, { Suspense, FC, useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Route, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import RoleProvider from 'contexts/RoleContext';
import { history } from 'helpers/history.helper';
import store from 'store';
import { PREFIX_ROUTES } from 'constants/routes.constant';
import AuthRoute from 'routes/auth.route';
import MainRoute from 'routes/main.route';
import ErrorBoundary from 'components/old-version/error-boundary/ErrorBoundary';
import PublicRoute from './routes/public.route';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';

const App: FC = () => {
  useEffect(() => {
    window.addEventListener('error', (error) => {
      if (/(.*)(chunk \d+ failed)(.*)/.test(error.message)) {
        alert(
          'A new version released. Need to reload the page to apply changes.',
        );
        window.location.reload();
      }
    });
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store.store}>
        <RoleProvider>
          <PersistGate loading={null} persistor={store.persistor}>
            <ConnectedRouter history={history}>
              <Suspense fallback={<div />}>
                <Switch>
                  <Route path={PREFIX_ROUTES.PUBLIC} component={PublicRoute} />
                  <Route path={PREFIX_ROUTES.AUTH} component={AuthRoute} />
                  <Route path={PREFIX_ROUTES.MAIN} component={MainRoute} />
                </Switch>
              </Suspense>
            </ConnectedRouter>
          </PersistGate>
        </RoleProvider>
      </Provider>
      <ToastContainer
        hideProgressBar={true}
        position={'top-right'}
        transition={Slide}
        limit={1}
      />
    </ErrorBoundary>
  );
};

export default App;
