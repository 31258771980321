import { combineReducers } from 'redux';
import { history } from 'helpers/history.helper';
import { connectRouter } from 'connected-react-router';
import { StoreState } from 'models/store';
import { createBrowserHistory } from 'history';
import accountReducer from './account/account.reducer';
import authReducer from './auth/auth.reducer';
import companiesReducer from './companies/companies.reducer';
import credentialReducer from './credential/credential.reducer';
import networkReducer from './network/network.reducer';
import notificationReducer from './notification/notification.reducer';
import verificationByPartnerReducer from './verifications-by-partner/verifications.reducer';
import verificationReducer from './verifications/verifications.reducer';
import verifyReducer from './verify/verify.reducer';
import marketplaceReducer from './marketplace/marketplace.reducer';
import messageReducer from './message/message.reducer';
import internalUserReducer from './users/users.reducer';
import permissionReducer from './permission/permission.reducer';

const rootReducer = combineReducers<StoreState>({
  router: connectRouter(history),
  auth: authReducer,
  account: accountReducer,
  verifications: verificationReducer,
  verificationByPartner: verificationByPartnerReducer,
  notification: notificationReducer,
  companies: companiesReducer,
  credential: credentialReducer,
  network: networkReducer,
  verify: verifyReducer,
  marketplace: marketplaceReducer,
  message: messageReducer,
  internalUser: internalUserReducer,
  permission: permissionReducer,
});

export default rootReducer;
