import queryString from 'query-string';

import authorizedRequest from 'apis/request/authorizedRequest';

import downloadFileRequest from './request/downloadFileRequest';
import { PREFIX_API, PREFIX_API_REPORT } from './request/config.endpoint';

export interface CreateCategory {
  _id?: string;
  categoryName: string;
  subtitle: string;
  imgId: string;
}
export interface UpdateCategory {
  categoryName?: string;
  subtitle?: string;
  imgId?: string;
}
export type LinkModel = {
  name: string;
  url: string;
};
export type Override = {
  isActive: boolean;
  detail: string;
};
export interface CreateListing {
  templateId?: string;
  partnerId?: string;
  imgIds: string[];
  vidIds?: string[];
  featuredImg: string;
  logoId: string;
  description: string;
  priority: number;
  type: number;
  categoryIds: string[];
  links: LinkModel[];
  overrideLink?: Override;
}
export interface CategoryModel {
  _id: string;
  dateCreated: string;
  dateUpdated: string;
  status: string;
  categoryName: string;
  subtitle: string;
  imgId: string;
  numberListing: number;
}
export interface ListingModel {
  dateCreated: string;
  dateUpdated: string;
  isCreatedByAdmin: boolean;
  partnerName: string;
  priority: number;
  status: string;
  templateId: string;
  templateName: string;
  totalIssued: number;
  type: number;
  __v: number;
  _id: string;
  slugs: {
    categories: string[];
    credentialName: string;
    issuerName: string;
  };
}
export interface ListCategoryResponse {
  data: CategoryModel[];
  page?: number;
  pageSize?: number;
  totalItem?: number;
  totalPage?: number;
}
export interface DetailListingModal {
  categories: any[];
  isCreatedByAdmin?: boolean;
  categoryIds: string[];
  dateCreated: string;
  dateUpdated: string;
  description: string;
  featuredImg: string;
  imgIds: string[];
  vidIds: string[];
  links: LinkModel[];
  logoId: string;
  partnerName: string;
  partner?: string;
  priority: number;
  status: string;
  templateExpiredTime: any;
  templateId: string;
  templateImgs: {
    imgId: string;
    logoId: string;
  };
  overrideLink?: Override;
  templateName: string;
  type: number;
  __v: number;
  _id: string;
}
export interface DetailListingModalResponse {
  data: DetailListingModal;
}
export interface ListListingResponse {
  data: ListingModel[];
  page?: number;
  pageSize?: number;
  totalItem?: number;
  totalPage?: number;
}
interface objectMessage {
  field: string;
  message: Array<string>;
}
export interface CategoryListFailResponse {
  message: Array<objectMessage>;
}
export interface IListCategoryParams {
  sort?: string;
  pageSize?: number;
  page?: number;
  status?: string;
  name?: string;
}
export interface IListListingParams {
  sort?: string;
  pageSize?: number;
  page?: number;
  status?: string;
}
export interface CommonListingApiParam {
  categoryIds?: string;
  endDate?: number;
  endTimeIssued?: number;
  page?: number;
  pageSize?: number;
  partnerName?: string;
  priority?: number;
  startDate?: number;
  startTimeIssued?: number;
  status?: string;
  templateName?: string;
  sort?: string;
}
export const createCategoryApi = (body: CreateCategory) => {
  return authorizedRequest.post<CreateCategory>(
    `${PREFIX_API}/marketplace/category`,
    body,
  );
};

export const updateCategoryApi = (id: string, body: UpdateCategory) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/marketplace/category/${id}`,
    body,
  );
};
export const getListCategoryApi = (params: IListCategoryParams) => {
  return authorizedRequest.get<ListCategoryResponse>(
    `${PREFIX_API}/marketplace/category`,
    { params },
  );
};

export const getCategoryByIdApi = (id: string) => {
  return authorizedRequest.get<ListCategoryResponse>(
    `${PREFIX_API}/marketplace/category/${id}`,
  );
};

export const deleteCategoryById = (id: string) => {
  return authorizedRequest.delete<void>(
    `${PREFIX_API}/marketplace/category/${id}`,
  );
};
export const getListListingApi = (params: IListCategoryParams) => {
  return authorizedRequest.get<ListListingResponse>(
    `${PREFIX_API}/marketplace/listing`,
    {
      params,
    },
  );
};
export const getListListingBusinessPendingApi = (
  params: IListCategoryParams,
) => {
  return authorizedRequest.get<ListListingResponse>(
    `${PREFIX_API}/marketplace/listing?status=pending&status=declined`,
    {
      params,
    },
  );
};
export const getDetailListingByIdApi = (id: string) => {
  return authorizedRequest.get<any>(`${PREFIX_API}/marketplace/listing/${id}`);
};
export const createListingApi = (body: CreateListing) => {
  return authorizedRequest.post<CreateListing>(
    `${PREFIX_API}/marketplace/listing`,
    body,
  );
};
export const updateListingApi = (id: string, body: CreateListing) => {
  return authorizedRequest.patch<CreateListing>(
    `${PREFIX_API}/marketplace/listing/${id}`,
    body,
  );
};
export const deleteListingById = (id: string) => {
  return authorizedRequest.delete<void>(
    `${PREFIX_API}/marketplace/listing/${id}`,
  );
};
export const updateStatusActiveListing = (id: string) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/marketplace/listing/active/${id}`,
  );
};
export const updateStatusDeActiveListing = (id: string) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/marketplace/listing/deactive/${id}`,
  );
};
export const approveListingForBusiness = (
  id: string,
  body: Partial<CreateListing>,
) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/marketplace/listing/approve/${id}`,
    body,
  );
};
export const declineListingForBusiness = (id: string) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/marketplace/listing/decline/${id}`,
  );
};
export const exportListingApi = (dataParams: CommonListingApiParam) => {
  const params = queryString.stringify(dataParams);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/marketplace/listing/export-report-link?${params}`,
  );
};
