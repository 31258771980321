import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { history } from 'helpers/history.helper';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import tokenManager from 'helpers/token-manager.helper';
import { doLogoutAction } from './auth/auth.action';
import rootReducer from './reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = routerMiddleware(history);
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware, middleware)),
);

sagaMiddleware.run(rootSaga);

tokenManager.setLogout(() => {
  store.dispatch(doLogoutAction());
});

const persistor = persistStore(store);

let currentValue: string;

function handleChange() {
  // eslint-disable-next-line func-names
  const previousValue = currentValue;
  currentValue = store.getState().auth?.token;

  if (previousValue !== currentValue) {
    tokenManager.setToken(currentValue);
  }
}

store.subscribe(handleChange);

export default { store, persistor };
