import ROUTES from 'constants/routes.constant';
import React, { FC, Suspense, lazy } from 'react';
import { RouteProps, Switch, Route } from 'react-router-dom';

const ResetPassword = lazy(
  () => import('containers/reset-password/ResetPassword'),
);
const ForgotPassword = lazy(
  () => import('containers/reset-password/ForgotPassword'),
);
const InvitationExpired = lazy(
  () => import('containers/reset-password/InvitationExpired'),
);
const routes: Array<RouteProps> = [
  { path: ROUTES.PUBLIC.RESET_PASSWORD, component: ResetPassword },
  { path: ROUTES.PUBLIC.FORGOT_PASSWORD, component: ForgotPassword },
  { path: ROUTES.PUBLIC.INVITATION_EXPIRED, component: InvitationExpired },
];

const PublicRoute: FC = () => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        {routes.map((i) => (
          <Route
            key={String(i.path)}
            exact={i.exact}
            path={i.path}
            component={i.component}
          />
        ))}
      </Switch>
    </Suspense>
  );
};

export default PublicRoute;
