import React, { FC } from 'react';
import withAuth from '../../hocs/withAuth';

import classes from './auth-layout.module.scss';

const AuthLayout: FC = ({ children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.main}>{children}</div>
    </div>
  );
};

export default withAuth(AuthLayout, { needAuth: false });
