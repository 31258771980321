import {
  CommonApiParam,
  CreateVerification,
  DeleteUser,
  ListVerificationsResponse,
  ListWebsite,
  VerificationsDetail,
  VerificationsReviewDetail,
} from 'models/store/verifications.model';
import authorizedRequest from 'apis/request/authorizedRequest';
import queryString from 'query-string';
import { PREFIX_API, PREFIX_API_REPORT } from 'apis/request/config.endpoint';
import downloadFileRequest from 'apis/request/downloadFileRequest';

export const getListVerificationsApi = (dataParams: CommonApiParam) => {
  return authorizedRequest.get<ListVerificationsResponse>(
    `${PREFIX_API}/users`,
    {
      params: dataParams,
    },
  );
};
export const getDetailVerificationsApi = (data: string) =>
  authorizedRequest.get<VerificationsDetail>(`${PREFIX_API}/${data}/detail`);

export const getDetailVerificationsReviewApi = (id: string) => {
  return authorizedRequest.get<VerificationsReviewDetail>(
    `${PREFIX_API}/admin-verify-user/${id}`,
  );
};

export const deleteUserApi = (id: string) =>
  authorizedRequest.delete<DeleteUser>(`${PREFIX_API}/user/${id}`);

export const declineUserApi = (id: string) =>
  authorizedRequest.post(`${PREFIX_API}/verify-user/${id}/decline`);

export const acceptUserApi = (id: string) =>
  authorizedRequest.post(`${PREFIX_API}/verify-user/${id}/approve`);
export interface ICreateVerification {
  _id: string;
  status: string;
  website: {
    _id: string;
    domain: string;
  };
  dateUpdated: string;
  user: {
    phoneNumber: {
      phone: string;
      dialCode: string;
    };
    firstName: string;
    lastName: string;
  };
}
export const createVerificationsApi = (params: CreateVerification) =>
  authorizedRequest.post<ICreateVerification>(
    `${PREFIX_API}/partner/verification`,
    params,
  );

export interface IStatisticData {
  last6Months: {
    [key: string]: { value: number; priority: number };
  };
  rating: { totalVerified: number; totalFailed: number };
  verificationsVolume: { [key: string]: { value: number; priority: number } };
  registeredVolume: { [key: string]: { value: number; priority: number } };
  verifiedVolume: { [key: string]: { value: number; priority: number } };
  lastSync: string;
}

export const getStatisticApi = (dataDate: CommonApiParam) => {
  return authorizedRequest.get<IStatisticData>(
    `${PREFIX_API}/users/statistics`,
    {
      params: dataDate,
    },
  );
};

export const getListWebsiteApi = (dataParams: CommonApiParam) =>
  authorizedRequest.get<ListWebsite>(`${PREFIX_API}/website`, {
    params: dataParams,
  });

export const exportVerificationsApi = (dataParams: CommonApiParam) => {
  const params = queryString.stringify(dataParams);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/users/export-report-link?${params}`,
  );
};
