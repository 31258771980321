import { getListRoleByInternalUserApi } from 'apis/permission.api';
import {
  getListInternalUserApi,
  getProfileInternalUserApi,
} from 'apis/users.api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getListInternalUserAction,
  getProfileUserAction,
} from './users.action';

function* handleGetListInternalUser({
  payload,
}: ReturnType<typeof getListInternalUserAction.request>) {
  try {
    const listUser: Await<ReturnType<
      typeof getListInternalUserApi
    >> = yield call(getListInternalUserApi, payload);

    const userIds = listUser?.data?.data?.map((item) => item._id);
    const listRoleByUser: Await<ReturnType<
      typeof getListRoleByInternalUserApi
    >> = yield call(getListRoleByInternalUserApi, { userIds });

    const dataRes = listUser?.data?.data?.map((item: any) => {
      let newItem = { ...item, roles: [] };
      for (let i = 0; i < listRoleByUser?.length; i++) {
        if (listRoleByUser[i]?.user?.includes(item?._id)) {
          newItem = {
            ...item,
            roles: listRoleByUser[i]?.roles,
          };
          listRoleByUser?.splice(i, 1);
          break;
        }
      }
      return newItem;
    });

    yield put(
      getListInternalUserAction.success({
        ...listUser?.data,
        data: dataRes,
      }),
    );
  } catch (e) {
    yield put(getListInternalUserAction.failure(e));
  }
}
function* handleGetProfileUser() {
  try {
    const response: Await<ReturnType<
      typeof getProfileInternalUserApi
    >> = yield call(getProfileInternalUserApi);

    yield put(getProfileUserAction.success(response?.data));
  } catch (e) {
    yield put(getProfileUserAction.failure(e));
  }
}

export default function* internalUserSaga() {
  yield takeLatest(
    getListInternalUserAction.request,
    handleGetListInternalUser,
  );
  yield takeLatest(getProfileUserAction.request, handleGetProfileUser);
}
