import { DatumVerify, ListVerifyResponse } from 'models/store/verify.model';
import { createAsyncAction } from 'typesafe-actions';

export interface IListVerifyParams {
  sort?: string;
  pageSize?: number;
  page?: number;
}

export interface IExportVerifyParams {
  status: string;
  startDate: number;
  endDate: number;
  dateString: string;
  page: number;
  sort: string;
  appName: string;
  clientId: string;
  clientSecret: string;
  redirectLink: string;
  logoutLink: string;
  clientLink: string;
  pageSize: number;
  verifierName: string;
  privacyPolicyLink: string;
}

interface objectMessage {
  field: string;
  message: Array<string>;
}

interface VerifyFailResponse {
  message: Array<objectMessage>;
}

export const getListVerify = createAsyncAction(
  `@verify/GET_LIST_VERIFY_REQUEST`,
  `@verify/GET_LIST_VERIFY_SUCCESS`,
  `@verify/GET_LIST_VERIFY_FAIL`,
)<IListVerifyParams, ListVerifyResponse, VerifyFailResponse>();

export const getDetailVerify = createAsyncAction(
  `@verify/GET_DETAIL_VERIFY_BY_PARTNER_REQUEST`,
  `@verify/GET_DETAIL_VERIFY_BY_PARTNER_SUCCESS`,
  `@verify/GET_DETAIL_VERIFY_BY_PARTNER_FAIL`,
)<string, DatumVerify, void>();
