import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import tokenManager from 'helpers/token-manager.helper';
import Request from './request';
import { toastError } from '../../helpers/notification.helper';

const authorizedRequest = new Request();

authorizedRequest.api.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  const { token } = tokenManager;
  newConfig.headers.common.Authorization =
    token && token !== 'guest' ? `Bearer ${token}` : '';
  return newConfig;
});

authorizedRequest.api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error && error.response && error.response.status === 401) {
      tokenManager.doLogout();
      tokenManager.showMessageExpiredOnce && toastError('Session Expired');
      tokenManager.doLogout();
      tokenManager.setShowMessageExpiredOnce(false);
      // eslint-disable-next-line no-throw-literal
      throw 401;
    }
    return Promise.reject(error.response);
  },
);

export default authorizedRequest;
