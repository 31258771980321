export const PREFIX_ROUTES = {
  MAIN: '',
  AUTH: '/auth',
  PUBLIC: '/public',
};

const ROUTES = {
  MAIN: {
    HOME: `${PREFIX_ROUTES.MAIN}/`,
    ACCOUNT: `${PREFIX_ROUTES.MAIN}/account`,
    VERIFICATION: `${PREFIX_ROUTES.MAIN}/verification`,
    NOTIFICATION: `${PREFIX_ROUTES.MAIN}/notification`,
    SETTING: `${PREFIX_ROUTES.MAIN}/setting`,
    DASHBOARD: `${PREFIX_ROUTES.MAIN}/dashboard`,
    MANUAL_IDV: `${PREFIX_ROUTES.MAIN}/manual-idv`,
    COMPANIES: `${PREFIX_ROUTES.MAIN}/companies`,
    CREDENTIAL: `${PREFIX_ROUTES.MAIN}/credential`,
    NETWORK: `${PREFIX_ROUTES.MAIN}/network`,
    VERIFY: `${PREFIX_ROUTES.MAIN}/verify`,
    MARKETPLACE: `${PREFIX_ROUTES.MAIN}/marketplace`,
    MESSAGE: `${PREFIX_ROUTES.MAIN}/message`,
    ROLE_PERMISSION: `${PREFIX_ROUTES.MAIN}/role-permission`,
    INTERNAL_USER: `${PREFIX_ROUTES.MAIN}/internal-user`,
    PROFILE: `${PREFIX_ROUTES.MAIN}/profile`,
    SETTINGS: `${PREFIX_ROUTES.MAIN}/settings`,
    COMPANIES_ID: `${PREFIX_ROUTES.MAIN}/companies/:id`,
  },
  AUTH: {
    LOGIN: `${PREFIX_ROUTES.AUTH}/login`,
    VERIFY_2FA: `${PREFIX_ROUTES.AUTH}/verify-2fa`,
    SIGNUP: `${PREFIX_ROUTES.AUTH}/sign-up`,
  },
  PUBLIC: {
    SHARE_CREDENTIAL: `${PREFIX_ROUTES.PUBLIC}/share-credential/:id`,
    SHARE_CREDENTIAL_LINK: `${PREFIX_ROUTES.PUBLIC}/share-credential/`,
    INFORMATION: `${PREFIX_ROUTES.PUBLIC}/information`,
    RESET_PASSWORD: `${PREFIX_ROUTES.PUBLIC}/reset-password/:id`,
    FORGOT_PASSWORD: `${PREFIX_ROUTES.PUBLIC}/forgot-password`,
    INVITATION_EXPIRED: `${PREFIX_ROUTES.PUBLIC}/invitation-expired`,
  },
};

export default ROUTES;
