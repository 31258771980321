import IcLogoGray from './ic_logo_gray.png';
import IcLogo from './logo.svg';
import IcLogoBlue from './ic-logo-blue.svg';
import IcLogoFooter from './ic-logo-footer.svg';

export default {
  IcLogo,
  IcLogoGray,
  IcLogoBlue,
  IcLogoFooter,
};
