import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  getDetailCompanyApi,
  getListCompaniesApi,
  getStatisticApi,
} from 'apis/companies.api';
import {
  getDetailCompany,
  getListCompanies,
  getStatisticCharts,
  updateParams,
} from './companies.action';
import { toastError } from '../../helpers/notification.helper';

function* handleGetListCompany({
  payload,
}: ReturnType<typeof getListCompanies.request>) {
  try {
    const response: Await<ReturnType<
      typeof getListCompaniesApi
    >> = yield getListCompaniesApi(payload);
    yield put(updateParams(payload));
    yield put(getListCompanies.success(response.data));
  } catch (e) {
    yield put(getListCompanies.failure(e));
  }
}

function* getStatisticChartsSaga({
  payload,
}: ReturnType<typeof getStatisticCharts.request>) {
  try {
    const response: Await<ReturnType<typeof getStatisticApi>> = yield call(
      getStatisticApi,
      payload,
    );
    const { data } = response;
    yield put(getStatisticCharts.success(data));
  } catch (e) {
    yield put(getStatisticCharts.failure(e));
  }
}

function* handleGetDetailCompany({
  payload,
}: ReturnType<typeof getDetailCompany.request>) {
  try {
    const response: Await<ReturnType<typeof getDetailCompanyApi>> = yield call(
      getDetailCompanyApi,
      payload,
    );
    const { data } = response;
    yield put(getDetailCompany.success(data));
  } catch (e) {
    toastError(e);
    yield put(getDetailCompany.failure(e));
  }
}

export default function* companiesSaga() {
  yield takeLatest(getListCompanies.request, handleGetListCompany);
  yield takeLatest(getDetailCompany.request, handleGetDetailCompany);
  yield takeLatest(getStatisticCharts.request, getStatisticChartsSaga);
}
