import { createReducer } from 'typesafe-actions';

import { VerifyStoreModel } from 'models/store/verify.model';
import { getDetailVerify, getListVerify } from './verify.action';

const INITIAL_STATE: VerifyStoreModel = {
  loading: false,
  listVerify: null,
  verifyDetail: undefined,
};

const verifyReducer = createReducer<VerifyStoreModel>(INITIAL_STATE)
  .handleAction(getListVerify.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListVerify.success, (state, { payload }) => ({
    ...state,
    listVerify: payload,
    loading: false,
  }))
  .handleAction(getListVerify.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getDetailVerify.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getDetailVerify.success, (state, { payload }) => ({
    ...state,
    verifyDetail: payload,
    loading: false,
  }))
  .handleAction(getDetailVerify.failure, (state) => ({
    ...state,
    verifyDetail: null,
    loading: false,
  }));

export default verifyReducer;
