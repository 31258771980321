import devConfig from './dev.config';
import productionConfig from './production.config';
import stagingConfig from './staging.config';

const appStage = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE.trim()
  : 'production';

interface ConfigModel {
  BASE_URL: string;
  CDN: string;
  NAME: string;
  PARTNER_URL: string;
  DENTITY_COM: string;
  DENTITY: string;
  VERIFY_POPUP_DYNAMIC: (id: string) => string;
  DENTITY_COM_V2: string;
  BASE_URL_OIDC: string;
  VERIFY_LINK_OIDC: string;
  MARKETPLACE_URL: string;
  ISSUE_LINK: string;
  CALLBACK_OUT: string;
  DID_ION: string;
}

// eslint-disable-next-line import/no-mutable-exports
let CONFIG: ConfigModel = devConfig;

if (appStage === 'staging') {
  CONFIG = stagingConfig;
} else if (appStage === 'production') {
  CONFIG = productionConfig;
}

export default CONFIG;
