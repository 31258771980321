import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { stringify } from 'query-string';
import tokenManager from 'helpers/token-manager.helper';
import moment from 'moment-timezone';
import CONFIG from '../../config';

const downloadFileRequest: AxiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-timezone': moment.tz.guess(),
  },
  paramsSerializer: (params) => {
    return stringify(params, { arrayFormat: 'separator' });
  },
  params: {},
});

downloadFileRequest.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  const { token } = tokenManager;
  newConfig.headers.common.Authorization =
    token && token !== 'guest' ? `Bearer ${token}` : '';
  return newConfig;
});

export default downloadFileRequest;
