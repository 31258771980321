// eslint-disable-next-line no-shadow
export enum Languages {
  EN = 'en',
}

class LanguageManager {
  currentLang: Languages = Languages.EN;

  changeLanguage = (lang: Languages) => {
    this.currentLang = lang;
  };
}

const languageManager = new LanguageManager();

export default languageManager;
