import { GenericData } from 'models/common.model';
import { VerificationModel } from 'models/store/verification.model';
import { createAsyncAction } from 'typesafe-actions';

export interface ListVerificationParams {
  page?: number;
  pageSize?: number;
  select?: string;
  sort?: string;
  populations?: string;
  where?: string;
  pattern?: string;
  content?: string;
}
export interface ListVerificationSuccessPayload {
  data: VerificationModel[];
  totalItem: number;
  page: number;
  totalPage: number;
  pageSize: number;
}

export const getListVerificationAction = createAsyncAction(
  `verification/GET_LIST_VERIFICATION`,
  `verification/GET_LIST_VERIFICATION_SUCCEEDED`,
  `verification/GET_LIST_VERIFICATION_FAILED`,
)<ListVerificationParams, ListVerificationSuccessPayload, string>();

interface DetailVerification {
  _id: string;
  user?: string;
  address?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  dateCreated: string;
  dateUpdated: string;
  status?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  verifyStatus?: string;
  dateOfBirth?: string;
  last4SSN?: string;
}

export type DetailVerificationSuccessPayload = GenericData<DetailVerification>;

export const getDetailVerificationAction = createAsyncAction(
  `verification/GET_DETAIL_VERIFICATION`,
  `verification/GET_DETAIL_VERIFICATION_SUCCEEDED`,
  `verification/GET_DETAIL_VERIFICATION_FAILED`,
)<string, DetailVerificationSuccessPayload, string>();

export type AcceptVerificationSuccessPayload = GenericData<DetailVerification>;

export const acceptVerificationUserAction = createAsyncAction(
  `verification/ACCEPT_VERIFICATION`,
  `verification/ACCEPT_VERIFICATION_SUCCEEDED`,
  `verification/ACCEPT_VERIFICATION_FAILED`,
)<string, AcceptVerificationSuccessPayload, string>();

export type DeclinedVerificationSuccessPayload = GenericData<
  DetailVerification
>;

export const declinedlVerificationAction = createAsyncAction(
  `verification/DECLINED_VERIFICATION`,
  `verification/DECLINED_VERIFICATION_SUCCEEDED`,
  `verification/DECLINED_VERIFICATION_FAILED`,
)<string, DeclinedVerificationSuccessPayload, string>();
