import React, { FC, lazy, Suspense, useMemo } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';

import ROUTES from 'constants/routes.constant';
import MainLayout from 'layouts/main/main.layout';
import useMineRoleContext from 'hooks/useMineRole';
import { ActionPermission } from 'constants/common.constant';

const Home = lazy(() => import('containers/home/Home'));
const Verification = lazy(() => import('containers/main-verification/index'));
const Credential = lazy(() => import('containers/credential/Credential'));
const Network = lazy(() => import('containers/network/Network'));
const Account_detail = lazy(
  () => import('containers/account/common/detail/Detail'),
);
const Account = lazy(() => import('containers/account/Account'));

const ManualIDV = lazy(() => import('containers/manual-idv/index'));
const CompaniesLazy = lazy(() => import('containers/companies'));
const CompaniesDetailLazy = lazy(() => import('containers/companies-detail'));
const Verify = lazy(() => import('containers/verify/Verify'));
const MarketPlace = lazy(() => import('containers/marketplace'));
const NewListing = lazy(
  () => import('containers/marketplace/newListing/NewListing'),
);
const EditListing = lazy(
  () => import('containers/marketplace/edit/EditListing'),
);
const ListingBusiness = lazy(
  () => import('containers/marketplace/listing-business/ListingBusiness'),
);
const CustomMessage = lazy(() => import('containers/message/index'));

const EditPermission = lazy(
  () => import('containers/role-permission/edit-permission/EditPermission'),
);

const RolePermission = lazy(
  () => import('containers/role-permission/RolePermission'),
);
const InternalUser = lazy(() => import('containers/users-management/index'));

const Profile = lazy(() => import('containers/subadmin-profile/index'));

const SettingsPlaid = lazy(() => import('containers/settings'));

const NotFound = lazy(() => import('containers/no-permission/index'));

const MainRoute: FC = () => {
  const { checkRoleIsBlock } = useMineRoleContext();

  const routes: Array<RouteProps> = useMemo(
    () => [
      {
        path: ROUTES.MAIN.HOME,
        component: Home,
        exact: true,
      },
      {
        path: ROUTES.MAIN.PROFILE,
        component: Profile,
        exact: true,
      },
      {
        path: `${ROUTES.MAIN.ACCOUNT}/:id`,
        component: Account_detail,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.ACCOUNT}/:id`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: ROUTES.MAIN.VERIFICATION,
        component: Verification,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.VERIFICATION}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: ROUTES.MAIN.CREDENTIAL,
        component: Credential,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.CREDENTIAL}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.NETWORK}/credential`,
        component: Network,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.NETWORK}/credential`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.NETWORK}/verify-request`,
        component: Network,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.NETWORK}/verify-request`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: ROUTES.MAIN.VERIFY,
        component: Verify,
        exact: true,
        block: checkRoleIsBlock(`${ROUTES.MAIN.VERIFY}`, ActionPermission.VIEW),
      },
      {
        path: ROUTES.MAIN.ACCOUNT,
        component: Account,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.ACCOUNT}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: ROUTES.MAIN.MANUAL_IDV,
        component: ManualIDV,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.MANUAL_IDV}`,
          ActionPermission.VIEW,
        ),
      },

      {
        path: ROUTES.MAIN.COMPANIES,
        component: CompaniesLazy,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.COMPANIES}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.COMPANIES}/:id`,
        component: CompaniesDetailLazy,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.COMPANIES}/:id`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: ROUTES.MAIN.MARKETPLACE,
        component: MarketPlace,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.MARKETPLACE}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.MARKETPLACE}/listing`,
        component: NewListing,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.MARKETPLACE}`,
          ActionPermission.CREATE,
        ),
      },
      {
        path: `${ROUTES.MAIN.MARKETPLACE}/listing/:id`,
        component: EditListing,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.MARKETPLACE}`,
          ActionPermission.UPDATE,
        ),
      },
      {
        path: `${ROUTES.MAIN.MARKETPLACE}/listing-business`,
        component: ListingBusiness,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.MARKETPLACE}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.MARKETPLACE}/listing-business/:id`,
        component: EditListing,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.MARKETPLACE}`,
          ActionPermission.UPDATE,
        ),
      },
      {
        path: `${ROUTES.MAIN.MESSAGE}`,
        component: CustomMessage,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.MESSAGE}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.ROLE_PERMISSION}`,
        component: RolePermission,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.ROLE_PERMISSION}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.ROLE_PERMISSION}/permission`,
        component: EditPermission,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.ROLE_PERMISSION}`,
          ActionPermission.CREATE,
        ),
      },
      {
        path: `${ROUTES.MAIN.INTERNAL_USER}`,
        component: InternalUser,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.INTERNAL_USER}`,
          ActionPermission.VIEW,
        ),
      },
      {
        path: `${ROUTES.MAIN.ROLE_PERMISSION}/permission/:id`,
        component: EditPermission,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.ROLE_PERMISSION}`,
          ActionPermission.UPDATE,
        ),
      },
      {
        path: ROUTES.MAIN.SETTINGS,
        component: SettingsPlaid,
        exact: true,
        block: checkRoleIsBlock(
          `${ROUTES.MAIN.SETTINGS}`,
          ActionPermission.VIEW,
        ),
      },
      {
        component: NotFound,
      },
    ],
    [checkRoleIsBlock],
  );

  return (
    <MainLayout>
      <Suspense fallback={<div />}>
        <Switch>
          {routes
            .filter((ele) => ele)
            .map((i: any) => (
              <Route
                key={!i?.block ? String(i.path) : 'not-found'}
                exact={i.exact}
                path={i.path}
                component={!i?.block ? i.component : NotFound}
              />
            ))}
        </Switch>
      </Suspense>
    </MainLayout>
  );
};

export default MainRoute;
