import { ActionPermission } from 'constants/common.constant';
import { UrlAndAction } from 'models/store/permission.model';
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleUrlAndAction } from 'store/permission/permission.action';
import CONFIG from '../config';

interface RoleValues {
  listMineRole: Array<UrlAndAction>;
  listPath: string[];
  checkRoleIsBlock: (url?: string, action?: ActionPermission) => boolean;
  isRoot: boolean;
}

export const RoleContext = createContext<RoleValues | undefined>(undefined);

const RoleProvider: FC = ({ children }) => {
  const { listMineRole, isLogging, isRoot } = useSelector((store) => ({
    listMineRole: store.permission.mineRole.list,
    isRoot: store.permission.mineRole.isRoot,
    isLogging: !!store.auth.token,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogging) {
      dispatch(getRoleUrlAndAction.request());
    }
  }, [isLogging, dispatch]);

  // TODO: Fake path is missing backend
  const listPath = useMemo(() => {
    return [
      ...listMineRole
        .filter((i) => i?.actions.includes(ActionPermission.VIEW))
        .map((item) => item?.path),
    ];
  }, [listMineRole]);

  const checkRoleIsBlock = useCallback(
    (url?: string, action?: ActionPermission) => {
      const pathName =
        typeof window !== 'undefined' ? window?.location?.pathname : null;
      if (isRoot) return false;
      if (action)
        return !listMineRole
          .find((i) => i.path === (url || pathName))
          ?.actions?.includes(action);
      if (!action)
        return !listMineRole
          .find((i) => i.path === (url || pathName))
          ?.actions?.includes(ActionPermission.VIEW);
    },
    [isRoot, listMineRole],
  );

  const values: RoleValues = useMemo(() => {
    return { listMineRole, listPath, checkRoleIsBlock, isRoot };
  }, [listMineRole, listPath, checkRoleIsBlock, isRoot]);

  return <RoleContext.Provider value={values}>{children}</RoleContext.Provider>;
};

export default RoleProvider;
