/* eslint-disable prettier/prettier */
import queryString from 'query-string';
import authorizedRequest from 'apis/request/authorizedRequest';
import { PREFIX_API, PREFIX_API_REPORT } from 'apis/request/config.endpoint';
import {
  CreateOfferResponse,
  CredentialStatistics,
  ListCredentialResponse,
  ListWalletInUserResponse,
  ListWalletResponse,
} from 'models/store/credential.model';
import {
  GetListWalletParams,
  IListCredentialParams,
} from '../store/credential/credential.action';
import { isProduction } from '../helpers/common.helper';

interface IExportTemplateParams {
  status?: string;
  startDate?: number;
  endDate?: number;
  page?: number;
  sort?: string;
  issuer?: string;
  name?: string;
  pageSize?: number;
}
export interface IListTemplateByBusinessIdParams {
  sort?: string;
  pageSize?: number;
  page?: number;
  status?: string;
  name?: string;
  noListing?: boolean;
}
export const getListCredentialApi = (params: IListCredentialParams) => {
  return authorizedRequest.get<ListCredentialResponse>(
    `${PREFIX_API}/credential/template`,
    { params },
  );
};
export const getListWalletApi = (params: GetListWalletParams) => {
  return authorizedRequest.get<ListWalletResponse>(`${PREFIX_API}/wallet`, {
    params,
  });
};
export const getDetailTemplatedApi = (id: string) => {
  return authorizedRequest.get<any>(`${PREFIX_API}/credential/template/${id}`);
};
export const getTemplateByBusinessId = (
  id: string,
  params: IListTemplateByBusinessIdParams,
) => {
  return authorizedRequest.get<any>(
    `${PREFIX_API}/credential/template?partnerId=${id}`,
    { params },
  );
};
export const getListWalletInUserApi = (id: string) => {
  return authorizedRequest.get<ListWalletInUserResponse>(
    `${PREFIX_API}/wallet/${id}`,
  );
};

export const deleteTemplateApi = (id: string) => {
  return authorizedRequest.delete<void>(
    `${PREFIX_API}/credential/template/${id}`,
  );
};

export const createOfferApi = (body: object) => {
  return authorizedRequest.post<CreateOfferResponse>(
    `${PREFIX_API}/credential`,
    body,
  );
};

export const revokeTeamplateApi = (id: string) => {
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/credential/template/${id}/revoke`,
  );
};

export const updateSourcesTemplateApi = (
  id: string,
  verifierSources: string[],
  includeGovernance: boolean = false,
) => {
  const data = { verifierSources, includeGovernance };
  return authorizedRequest.patch<void>(
    `${PREFIX_API}/credential/template/${id}`,
    { ...data },
  );
};
export const getChartsCredentials = (params: any) => {
  return authorizedRequest.get<CredentialStatistics>(
    `${PREFIX_API}/credential/statistics`,
    {
      params,
    },
  );
};

export const getLinkDownLoadTemplate = (
  dataParams: Partial<IExportTemplateParams>,
) => {
  const params = queryString.stringify(dataParams);
  return authorizedRequest.get<{ link: string }>(
    `${PREFIX_API_REPORT}/credential/template/export-report-link?${params}`,
  );
};
