import { createReducer } from 'typesafe-actions';
import { ActionPermission } from 'constants/common.constant';
import produce from 'immer';

import { PermissionStoreModel } from 'models/store/permission.model';
import {
  getListRolePermission,
  getListTreePermission,
  getRoleUrlAndAction,
} from './permission.action';

const INITIAL_STATE: PermissionStoreModel = {
  loading: false,
  listTreeRolePermission: null,
  listRolePermission: undefined,
  mineRole: {
    isRoot: false,
    list: [],
  },
};

const permissionReducer = createReducer<PermissionStoreModel>(INITIAL_STATE)
  .handleAction(getListTreePermission.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListTreePermission.success, (state, { payload }) => ({
    ...state,
    listTreeRolePermission: payload,
    loading: false,
  }))
  .handleAction(getListTreePermission.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getListRolePermission.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getListRolePermission.success, (state, { payload }) => ({
    ...state,
    listRolePermission: payload,
    loading: false,
  }))
  .handleAction(getListRolePermission.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(getRoleUrlAndAction.request, (state) =>
    produce(state, (draft) => {
      draft.mineRole.isRoot = false;
      draft.mineRole.list = [];
    }),
  )
  .handleAction(getRoleUrlAndAction.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.mineRole.isRoot = payload.isRoot;
      draft.mineRole.list = [
        ...payload.list,
        // ...PATH_MISSING.map((i) => ({
        //   path: i,
        //   actions: [ActionPermission.ACCESS],
        // })),
      ];
    }),
  )
  .handleAction(getRoleUrlAndAction.failure, (state) =>
    produce(state, (draft) => {
      draft.mineRole.isRoot = false;
      draft.mineRole.list = [];
    }),
  );

export default permissionReducer;
